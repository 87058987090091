import React, { useState } from 'react';
import axios from 'axios';

import CardDialog from '~/components/CardDialog';
import { Caption, FsButton, FsIconButton, Heading, LoadingSwitch, Text } from '~/components/core';
import { EyeIcon, PencilIcon } from '~/components/icons';
import { useSysconfig } from '~/components/SystemConfiguration//SystemConfigurationScreen';
import useDataFetcher from '~/components/useDataFetcher';
import { reportAxiosError } from '~/Utils';

import type { VitesseConfiguration } from './ConfigurationFormDialog';
import VitesseSetupDialog from './ConfigurationFormDialog';

interface DetailProps {
  label: string;
  text: string;
  isSecret?: boolean;
}

const Detail: React.FC<DetailProps> = ({ label, text, isSecret = false }) => {
  const [isHidden, setIsHidden] = useState(true);
  return (
    <div className="min-w-40 mr-40">
      <Caption variant={Caption.VARIANTS.LABEL}>{label}</Caption>
      <div className="flex items-center">
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
          {isSecret && isHidden ? '..........' : text}
        </Text>
        {isSecret ? <FsIconButton icon={EyeIcon} onClick={() => setIsHidden((prev) => !prev)} /> : null}
      </div>
    </div>
  );
};

const InternalAdminVitesse: React.FC = () => {
  const [configurationFormOpen, setConfigurationFormOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const { isLoading, isError, data, reloadData } = useDataFetcher(
    `/api/v1/vitesse/config/organizations/${organization.id}`
  );

  const vitesseConfiguration = data?.configuration;
  const isEnabled = !!vitesseConfiguration;

  const containerWithBorderCn = 'border border-solid border-slate-600 rounded-md p-24 mt-20 w-full';
  const sectionTitleCn = 'flex items-center';
  const sectionDetailsCn = 'mt-12 flex';

  const handleSubmitVitesseConfiguration = async (values: VitesseConfiguration) => {
    try {
      await axios.put(`/api/v1/vitesse/config/organizations/${organization.id}`, values);
      await reloadData();
      setConfigurationFormOpen(false);
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };
  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <CardDialog noCardTitle>
        {isEnabled ? (
          <>
            <div className={containerWithBorderCn}>
              <div className={sectionTitleCn}>
                <Heading variant={Heading.TYPES.H4}>Vitesse Details</Heading>
                <FsIconButton
                  icon={PencilIcon}
                  onClick={() => {
                    setConfigurationFormOpen(true);
                  }}
                />
              </div>
              <div className={sectionDetailsCn}>
                <Detail label="Client ID" text={vitesseConfiguration?.client_id} />
                <Detail label="Client Secret" text={vitesseConfiguration?.client_secret} isSecret />
              </div>
            </div>
            <div className={containerWithBorderCn}>
              <div className={sectionTitleCn}>
                <Heading variant={Heading.TYPES.H4}>Webhook</Heading>
              </div>
              <div className={sectionDetailsCn}>
                <Detail label="Username" text={vitesseConfiguration?.webhook_username} />
                <Detail label="Password" text={vitesseConfiguration?.webhook_password} isSecret />
                <Detail label="Webhook - URL" text={vitesseConfiguration?.webhook_url} />
              </div>
            </div>
          </>
        ) : (
          <FsButton
            color="primary"
            variant="contained"
            className="mb-40 mt-40"
            onClick={() => {
              setConfigurationFormOpen(true);
            }}
          >
            Enable Vitesse Integration
          </FsButton>
        )}
      </CardDialog>
      {configurationFormOpen ? (
        <VitesseSetupDialog
          onSubmit={handleSubmitVitesseConfiguration}
          configuration={vitesseConfiguration}
          onClose={() => {
            setConfigurationFormOpen(false);
          }}
        />
      ) : null}
    </LoadingSwitch>
  );
};

export default InternalAdminVitesse;

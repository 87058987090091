import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getIn, useFormikContext } from 'formik';
import _ from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { isAllLobsSelected } from '~/components/core/Formik/LobMultiSelectWithChipsFormik';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { reportAxiosError } from '../../../../Utils';
import { Heading } from '../../../core';
import useFormikChangeListener from '../../../core/Formik/FormikChangeListener';
import { useSysconfig } from '../../SystemConfigurationScreen';

import Condition from './Condition';

const CONDITION_FIELD_IDS = {
  ID: 'id',
  CONDITION_KEY: 'condition_key',
  OPERATOR_1: 'operator_1',
  OPERATOR_2: 'operator_2',
  VALUE_1: 'value_1',
  VALUE_2: 'value_2',
};

const Conditions = ({
  subOrganizationIds,
  lobs,
  coverage,
  eventKey,
  conditionsFieldId,
  disabled,
  setIsLoading,
  automation_type,
  loadConditionsFieldsIds,
  shouldLoadConditions,
}) => {
  const { organization } = useSysconfig();
  const [conditionOptions, setConditionOptions] = useState([]);
  const { setFieldValue, values } = useFormikContext();

  const conditions = getIn(values, conditionsFieldId);
  const currentConditionKeys = conditions.map((c) => c[CONDITION_FIELD_IDS.CONDITION_KEY]);

  const updateOptions = useCallback(
    (newOptions) => {
      setConditionOptions(newOptions);

      const currentValues = getIn(values, conditionsFieldId);
      const newOptionKeys = newOptions.map((o) => o.key);

      const intersection = currentValues.filter(
        (condition) =>
          newOptionKeys.includes(condition[CONDITION_FIELD_IDS.CONDITION_KEY]) ||
          !condition[CONDITION_FIELD_IDS.CONDITION_KEY]
      );

      if (!_.isEqual(currentValues, intersection)) {
        setFieldValue(conditionsFieldId, intersection);
      }
    },
    [conditionsFieldId, setFieldValue, values]
  );
  const isAllLobs = values.is_all_lobs;
  const loadConditions = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`/api/v1/${automation_type}/${organization.id}/condition_options`, {
        params: {
          sub_organization_ids: subOrganizationIds,
          lobs,
          is_all_lobs: isAllLobs,
          coverage,
          event_key: eventKey,
        },
      });
      updateOptions(response.data);
    } catch (error) {
      await reportAxiosError(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    setIsLoading,
    automation_type,
    organization.id,
    subOrganizationIds,
    lobs,
    isAllLobs,
    coverage,
    eventKey,
    updateOptions,
  ]);

  useFormikChangeListener({
    listenForKeys: loadConditionsFieldsIds,
    onChange: async () => {
      if (!!isAllLobs !== isAllLobsSelected(lobs)) {
        // this case is invalid, its because Lobs multiselect updates the values in 2 steps so we want to skip the first step
        return;
      }
      if (shouldLoadConditions) {
        await loadConditions();
      } else {
        updateOptions([]);
      }
    },
    runOnFirstRender: true,
  });

  const addCondition = () => {
    setFieldValue(conditionsFieldId, [
      ...conditions,
      {
        [CONDITION_FIELD_IDS.CONDITION_KEY]: '',
        [CONDITION_FIELD_IDS.OPERATOR_1]: '',
        [CONDITION_FIELD_IDS.OPERATOR_2]: '',
        [CONDITION_FIELD_IDS.VALUE_1]: '',
        [CONDITION_FIELD_IDS.VALUE_2]: '',
      },
    ]);
  };

  const removeCondition = (index) => {
    setFieldValue(conditionsFieldId, [
      ...conditions.slice(0, index),
      ...conditions.slice(index + 1, conditions.length),
    ]);
  };

  const availableConditionOptions = (index) => {
    const indexCondition = getIn(values, `${conditionsFieldId}[${index}][${CONDITION_FIELD_IDS.CONDITION_KEY}]`);
    return conditionOptions.filter(
      (option) => !currentConditionKeys.includes(option.key) || option.key === indexCondition
    );
  };

  return (
    <Grid className="rounded-md bg-slate-200 p-20" container spacing={2}>
      <Grid item xs={12}>
        <Heading variant={Heading.TYPES.H4} className="mb-12">
          Conditions
        </Heading>
        <Grid container spacing={4}>
          {(!_.isEmpty(conditionOptions) || (!_.isEmpty(conditions) && !disabled)) &&
            conditions.map((condition, index) => (
              <Grid key={`condition_${index}`} item xs={12}>
                <Condition
                  conditionFieldId={`${conditionsFieldId}[${index}]`}
                  title={`Condition #${index + 1}`}
                  conditionOptions={availableConditionOptions(index)}
                  disabled={disabled}
                  onRemoveCondition={() => removeCondition(index)}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          onClick={addCondition}
          disabled={disabled || _.isEmpty(conditionOptions) || conditions.length === conditionOptions.length}
        >
          <AddIcon />
          Add Condition
        </Button>
      </Grid>
    </Grid>
  );
};

Conditions.propTypes = {
  subOrganizationIds: PropTypes.array,
  lobs: PropTypes.arrayOf(PropTypes.string),
  eventKey: PropTypes.string,
  coverage: PropTypes.string.isRequired,
  conditionsFieldId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  automation_type: PropTypes.oneOf(['automatic_exposures', 'automatic_communications']),
  loadConditionsFieldsIds: PropTypes.arrayOf(PropTypes.string),
  shouldLoadConditions: PropTypes.bool,
};

export default Conditions;
export { CONDITION_FIELD_IDS };

import React, { useCallback, useEffect, useState } from 'react';
import { Switch } from '@material-ui/core';
import _ from 'lodash';

import { useStyles } from '~/assets/styles';
import AlertBanner from '~/components/core/AlertBanner';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { Card } from '~/components/core/Cards/Card';
import TextField from '~/components/core/Molecules/Fields/TextField';

import CardDialog from '../../../CardDialog';
import MultiSelectFilter from '../../../core/MultiSelectFilter/MultiSelectFilter';
import { Text } from '../../../core/TextComponents';
import { useSysconfig } from '../../SystemConfigurationScreen';
import { useOrganizationUnits } from '../../Users/hooks/useOrganizationUnits';
import { isOrgHierarchyWorkflowsFeatureEnabled } from '../../Users/OrganizationUnits/Utils';

import { useAdjustersData } from './Hooks/useAdjustersData';
import { useLicenses } from './Hooks/useLicenses';
import { OrganizationAdjusters } from './OrganizationAdjusters/OrganizationAdjusters';
import { GeneralLicensesExpirationAlertBanner } from './GeneralLicensesExpirationAlertBanner';
import { getStateName, stateCodesArray } from './utils';

import styles from './AdjustersManagementTab.module.scss';

export const AdjusterManagementTab = () => {
  const classes = useStyles();
  const { adjusters, isError, isLoading, reloadAdjusters } = useAdjustersData();
  const { organization } = useSysconfig();
  const { userToUnitDict } = useOrganizationUnits({ organization });
  const [selectedSupervisors, setSelectedSupervisors] = useState([]);
  const [supervisorsOptions, setSupervisorsOptions] = useState([]);
  const [selectedTitles, setSelectedTitle] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [titlesOptions, setTitlesOptions] = useState([]);
  const [searchAdjusterText, setSearchAdjusterText] = useState('');
  const [showActiveUsersOnly, setShowActiveUsersOnly] = useState(true);
  const [showExpiredOnly, setShowExpiredOnly] = useState(false);
  const { isLicensesFeatureEnabled, isLicenseCorrelationEnabled } = useLicenses();

  const isOrgHierarchyWorkflowsEnabled = React.useMemo(() => {
    return isOrgHierarchyWorkflowsFeatureEnabled({ organization });
  }, [organization]);

  const getSupervisorName = React.useCallback(
    (user) =>
      (isOrgHierarchyWorkflowsEnabled ? userToUnitDict[user.id]?.leader_user_name : user.supervisor_name)?.trim(),
    [isOrgHierarchyWorkflowsEnabled, userToUnitDict]
  );

  useEffect(() => {
    if (!adjusters) {
      return;
    }

    const supervisors = _.uniq(adjusters.map((user) => getSupervisorName(user)).filter((supervisor) => !!supervisor));

    if (supervisors.length) {
      setSupervisorsOptions(supervisors);
    }

    const titles = _.uniq(adjusters.map((user) => user.title?.trim()).filter((title) => !!title));

    if (titles.length) {
      setTitlesOptions(titles);
    }
  }, [adjusters, getSupervisorName, setTitlesOptions]);

  const expiredLicensesCount = React.useMemo(() => {
    if (!adjusters) return 0;

    return adjusters.reduce((acc, user) => {
      const count = user.expiredLicenses + user.expiringLicenses;
      return acc + count;
    }, 0);
  }, [adjusters]);

  const filterAdjusters = useCallback(
    (user) => {
      const doesTitleMatch = selectedTitles.length ? selectedTitles.includes(user.title?.trim() || '') : true;
      const doesSupervisorMatch = selectedSupervisors.length
        ? selectedSupervisors.includes(getSupervisorName(user) || '')
        : true;
      const doesUserNameMatch =
        `${user.first_name} ${user.last_name}`.toLowerCase().indexOf(searchAdjusterText.toLowerCase().trim()) > -1;
      const activeMatch = showActiveUsersOnly ? !user.is_removed : true;
      const expiredLicenseMatch = showExpiredOnly ? !!user.expiredLicenses || !!user.expiringLicenses : true;
      const licensesMatch = selectedStates.length
        ? user.licenses.map((license) => license.state).some((state) => selectedStates.includes(state))
        : true;

      return (
        doesTitleMatch &&
        doesSupervisorMatch &&
        doesUserNameMatch &&
        activeMatch &&
        licensesMatch &&
        expiredLicenseMatch
      );
    },
    [
      getSupervisorName,
      searchAdjusterText,
      selectedStates,
      selectedSupervisors,
      selectedTitles,
      showActiveUsersOnly,
      showExpiredOnly,
    ]
  );

  const licensesInfoTitle = `License correlation is turned ${isLicenseCorrelationEnabled ? 'on' : 'off'}`;
  const licensesInfoText = isLicenseCorrelationEnabled
    ? 'Make sure to update adjusters licenses, or turn it off through the Assignments configuration'
    : 'To turn it on, go to the Assignments configuration';

  return (
    <CardDialog noCardTitle containerClassName={styles.adjustersManagementTab}>
      <Grid container style={{ marginTop: '24px' }}>
        <Grid item md={12}>
          <div className={classes.cardDivRow} style={{ marginTop: '0px', marginBottom: '0px' }}>
            <CardDialog title="ADJUSTERS">
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextField
                    className={classes.textField}
                    InputProps={{
                      placeholder: 'Search Adjuster',
                    }}
                    style={{ width: '220px' }}
                    value={searchAdjusterText}
                    onChange={(value) => setSearchAdjusterText(value)}
                  />
                </Grid>
                <Grid item xs={12} className={styles.filtersContainer}>
                  <Card title="Filters" cardType={Card.CARD_TYPES.SECONDARY}>
                    <div className={styles.filtersRow}>
                      <MultiSelectFilter
                        label={isOrgHierarchyWorkflowsEnabled ? 'By Unit Leader' : 'By Supervisor'}
                        value={selectedSupervisors}
                        onChange={setSelectedSupervisors}
                        options={supervisorsOptions}
                        withOptionChips
                      />
                      <MultiSelectFilter
                        label="By Title"
                        value={selectedTitles}
                        onChange={setSelectedTitle}
                        options={titlesOptions}
                        withOptionChips
                      />
                      {isLicensesFeatureEnabled ? (
                        <MultiSelectFilter
                          label="By States Licenses"
                          value={selectedStates}
                          onChange={setSelectedStates}
                          options={stateCodesArray}
                          renderOption={getStateName}
                          withOptionChips
                        />
                      ) : null}

                      <div className={styles.switchContainer}>
                        <Switch
                          checked={showExpiredOnly}
                          onChange={() => setShowExpiredOnly(!showExpiredOnly)}
                          className={classes.formsSwitch}
                          size="small"
                        />
                        <span>Show only licenses with a close expiration date</span>
                      </div>
                      <div className={styles.switchContainer}>
                        <Switch
                          checked={showActiveUsersOnly}
                          onChange={() => setShowActiveUsersOnly(!showActiveUsersOnly)}
                          className={classes.formsSwitch}
                          size="small"
                        />
                        <Text variant="sm">Show only active users</Text>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </CardDialog>
          </div>
        </Grid>

        <Grid item md={12}>
          <div className={styles.tableContainer}>
            {isLicensesFeatureEnabled ? (
              <AlertBanner
                withIcon
                alertType="info"
                title={licensesInfoTitle}
                note={licensesInfoText}
                className={styles.alertBanner}
              />
            ) : null}

            {!!expiredLicensesCount && isLicensesFeatureEnabled && (
              <GeneralLicensesExpirationAlertBanner count={expiredLicensesCount} />
            )}

            <OrganizationAdjusters
              adjusters={adjusters}
              isError={isError}
              isLoading={isLoading}
              reloadAdjusters={reloadAdjusters}
              filterFunc={filterAdjusters}
            />
          </div>
        </Grid>
      </Grid>
    </CardDialog>
  );
};

import React from 'react';
import axios from 'axios';

import Editor from '~/components/core/Editor';
import Text from '~/components/core/TextComponents/Text';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';

import { getRelevantFieldTitle } from './EmailConfigurationBuilderContainer';
import type { EmailConfigurationEditorProps } from './emailConfigurationTypes';

const EmailConfigurationEditor: React.FC<EmailConfigurationEditorProps> = ({
  handleChange,
  currentEmailSubject,
  currentEmailSignature,
  isSubOrgEmailConfiguration,
  suggestions,
}: EmailConfigurationEditorProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();

  const onUploadFinish = async (
    file: File,
    config: { onUploadProgress: (progressEvent: { loaded: number; total: number }) => void }
  ) => {
    const { data } = await axios.post(
      `/api/v1/email_configuration/organizations/${organization.id}/stored_files/upload_url`,
      {
        file_size: file.size,
        file_type: file.type,
        file_name: file.name,
      }
    );
    const uploadedUrl = data.upload_url;
    const storageFileName = data.storage_filename;
    await axios.put(uploadedUrl, file, config);
    const { data: publicUrl } = await axios.post(
      `/api/v1/email_configuration/organizations/${organization.id}/stored_files/upload_finished_callback`,

      {
        exposure_ids: [],
        storage_filename: storageFileName,
        file_size: file.size,
        file_type: file.type,
        file_name: file.name,
        last_modified: new Date(file.lastModified).toISOString(),
        type: '',
        summary: '',
        document_name: '',
        document_date: '',
        document_date_received: '',
      }
    );
    return publicUrl.url;
  };
  return (
    <>
      <div className="my-16">
        <Text variant={Text.VARIANTS.SM} className="my-12">
          {getRelevantFieldTitle(isSubOrgEmailConfiguration, 'Email Subject')}
        </Text>
        <Editor
          displayConfig={{ suggestions: true }}
          content={currentEmailSubject}
          onChange={(value) => handleChange(value, 'email_default_title')}
          suggestionOptions={suggestions.emailTitleTokens}
        />
      </div>
      <div className="my-16">
        <Text variant={Text.VARIANTS.SM} className="my-12">
          {getRelevantFieldTitle(isSubOrgEmailConfiguration, 'Signature')}
        </Text>
        <Editor
          className="min-h-[150px] overflow-auto"
          content={currentEmailSignature}
          onChange={(value) => handleChange(value, 'email_signature_html_template')}
          displayConfig={{
            font: true,
            textColor: true,
            textStyle: true,
            align: true,
            link: true,
            suggestions: true,
            images: true,
          }}
          suggestionOptions={suggestions.emailSignatureTokens}
          onUploadFinish={onUploadFinish}
        />
      </div>
    </>
  );
};

export default EmailConfigurationEditor;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get, isEqual } from 'lodash';

import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import { getLobDescription } from '~/Utils/lobUtils';

import useLobOptions from '../../../TPA/LOB/hooks/useLobOptions';
import ArrayMultiselectFieldWithChipsFormik from '../../ArrayMultiselectFieldWithChipsFormik';
import useFormikChangeListener from '../FormikChangeListener';

const ALL_ID = 0;
const ALL_OPTION = {
  id: ALL_ID,
  label: 'All (inc. future lobs)',
  value: ALL_ID,
};

const LobMultiSelectWithChipsFormik = ({
  lobsFieldId,
  label = 'Lines of Business',
  showOnly = false,
  disabled = false,
  subOrganizationIds = [],
  filterLobsFunc,
  isAllLobs = false,
  allLobsFieldId,
  subOrgFieldId,
  shouldResetOnSubOrgChange = true,
}) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext();
  const { lobConfigurationsDict } = useLobConfiguration();
  const { lobOptions } = useLobOptions({
    subOrganizationIds,
    filterLobsFunc,
    isAllLobs,
  });
  const lobs =
    lobOptions?.map((lob) => ({
      id: lob,
      label: getLobDescription(lob, lobConfigurationsDict),
      value: lob,
    })) || [];

  // set all lobs when all lobs is selected
  useFormikChangeListener({
    listenForKeys: [lobsFieldId],
    onChange: (newValues, _) => {
      const lobsValues = get(newValues, lobsFieldId);
      if (!allLobsFieldId) {
        return;
      }

      setFieldValue(allLobsFieldId, lobsValues?.includes(ALL_ID));
    },
    runOnFirstRender: true,
  });

  // set on first render the initial value of all lobs
  useEffect(() => {
    const allLobs = get(values, allLobsFieldId, false);
    if (allLobs) {
      setFieldValue(lobsFieldId, [ALL_ID]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // reset all lobs when sub org changes
  useFormikChangeListener({
    listenForKeys: [subOrgFieldId],
    onChange: (newValues, previousValues) => {
      const previousSubOrg = get(previousValues, subOrgFieldId);
      const currentSubOrg = get(newValues, subOrgFieldId);
      if (!shouldResetOnSubOrgChange || isEqual(previousSubOrg, currentSubOrg)) {
        return;
      }

      setFieldValue(allLobsFieldId, false);
      setFieldValue(lobsFieldId, []);
      setFieldTouched(lobsFieldId, false);
    },
    runOnFirstRender: true,
  });

  return (
    <ArrayMultiselectFieldWithChipsFormik
      showOnly={showOnly}
      disabled={disabled}
      id={lobsFieldId}
      label={label}
      options={lobs}
      allOption={ALL_OPTION}
    />
  );
};

LobMultiSelectWithChipsFormik.propTypes = {
  lobsFieldId: PropTypes.string.isRequired,
  label: PropTypes.string,
  showOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  subOrganizationIds: PropTypes.arrayOf(PropTypes.number),
  filterLobsFunc: PropTypes.func,
  isAllLobs: PropTypes.bool,
  allLobsFieldId: PropTypes.string,
  subOrgFieldId: PropTypes.string,
  shouldResetOnSubOrgChange: PropTypes.bool,
};

export const isAllLobsSelected = (ids) => ids.includes(ALL_OPTION.id);

export default LobMultiSelectWithChipsFormik;

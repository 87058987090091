import React from 'react';

import Grid from '~/components/core/Atomic/Grid/Grid';
import TextField from '~/components/core/Molecules/Fields/TextField';
import TextFieldFormik from '~/components/TextFieldFormik';

import copies from '../../copies.json';
import FieldConfigurationCard from '../../FieldConfigurationCard/FieldConfigurationCard';
import BaseConfigurationSection from '../BaseConfigurationSection';

const ID_PREFIX = 'configuration.involved_parties';

interface InvolvedEntitySectionPropsType {
  entityId: string;
  entityDesc: string;
  baseInvolvedFields: string[];
  involvedFields: string[];
  itemsIdPrefix?: string;
  itemsFieldIds?: string[];
  withIcdCodes?: boolean;
}

const InvolvedEntitySection: React.FC<InvolvedEntitySectionPropsType> = ({
  entityId,
  entityDesc,
  baseInvolvedFields,
  involvedFields,
  itemsIdPrefix,
  itemsFieldIds = [],
  withIcdCodes = false,
}): JSX.Element => {
  const id = `${ID_PREFIX}.${entityId}`;

  return (
    <BaseConfigurationSection title={entityDesc} subtitle={`Key: ${entityId}`} isHidden={false} isSection>
      <Grid item xs={6}>
        <TextFieldFormik id={`${id}.desc`} value={entityDesc} label="Display Name" fullWidth disabled />
      </Grid>
      <Grid item xs={6}>
        <TextField id={id} value={entityId} label="Key" fullWidth disabled />
      </Grid>
      {baseInvolvedFields.map((fieldId) => (
        <FieldConfigurationCard
          key={fieldId}
          idPrefix={id}
          fieldId={fieldId}
          disabledCollapse={false}
          disableCategories
        />
      ))}
      {involvedFields.map((fieldId) => (
        <FieldConfigurationCard
          key={fieldId}
          idPrefix={id}
          fieldId={fieldId}
          disabledCollapse={false}
          disableCategories
        />
      ))}
      {itemsIdPrefix &&
        itemsFieldIds?.map((fieldId) => (
          <FieldConfigurationCard
            key={fieldId}
            idPrefix={`${id}.${itemsIdPrefix}`}
            fieldId={fieldId}
            disabledCollapse={false}
            disableCategories
          />
        ))}
      {withIcdCodes && (
        <FieldConfigurationCard
          idPrefix={id}
          fieldId="icd_codes"
          disabledCollapse={false}
          disableCategories
          infoTooltipText={copies.icd_codes_tooltip}
        />
      )}
    </BaseConfigurationSection>
  );
};

export default InvolvedEntitySection;

import React, { useState } from 'react';
import type { FormikValues } from 'formik';
import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';

import { useStyles } from '~/assets/styles';
import CheckboxFormik from '~/components/CheckboxFormik';
import ArrayMultiselectFieldWithChipsFormik from '~/components/core/ArrayMultiselectFieldWithChipsFormik';
import Grid from '~/components/core/Atomic/Grid/Grid';
import useFormikChangeListener from '~/components/core/Formik/FormikChangeListener';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import FsTooltip from '~/components/core/FsWrappers/FsTooltip/FsTooltip';
import TextField from '~/components/core/Molecules/Fields/TextField';
import TextFieldFormik from '~/components/TextFieldFormik';

import FieldConfigurationCard from '../../FieldConfigurationCard/FieldConfigurationCard';
import BaseConfigurationSection from '../BaseConfigurationSection';

const ID_PREFIX = 'configuration.incident_details.pre_defined_fields';

type BaseAdditionalInformationSectionProps = {
  title: string;
  sectionKey: string;
  sectionKeyPrefix?: string;
  fieldIds: string[];
};

const BaseAdditionalInformationSection = ({
  title,
  sectionKey,
  sectionKeyPrefix,
  fieldIds,
}: BaseAdditionalInformationSectionProps): JSX.Element => {
  const { values, setFieldValue } = useFormikContext();
  const sectionKeyPath = sectionKeyPrefix
    ? `${ID_PREFIX}.${sectionKeyPrefix}.${sectionKey}`
    : `${ID_PREFIX}.${sectionKey}`;
  const classes = useStyles();
  const [isHidden, setIsHidden] = useState(false);
  const categories = get(values, 'configuration.categories', [])?.map((category: { id: string; desc: string }) => ({
    id: category.id,
    value: category.id,
    label: category.desc,
  }));

  const isMandatoryFieldsExists = (values: FormikValues) =>
    fieldIds?.some((fieldId) => get(values, `${sectionKeyPath}.${fieldId}.mandatory`, false));

  // Enforce all section to be hidden if the section active is set to false
  useFormikChangeListener({
    listenForKeys: [`${sectionKeyPath}.active`],
    onChange: (values: FormikValues) => {
      !get(values, `${sectionKeyPath}.active`, false) ? setIsHidden(true) : setIsHidden(false);
    },
    runOnFirstRender: true,
  });

  // Enforce the section to be mandatory if any of the section fields is mandatory
  useFormikChangeListener({
    listenForKeys: fieldIds.forEach((fieldId) => `${sectionKeyPath}.${fieldId}.mandatory`),
    onChange: (values: FormikValues) => {
      if (isMandatoryFieldsExists(values)) {
        setFieldValue(`${sectionKeyPath}.mandatory`, true);
      }
    },
    runOnFirstRender: true,
  });

  return (
    <BaseConfigurationSection title={title} subtitle={`Key: ${sectionKey}`} isHidden={isHidden} isSection>
      <Grid item xs={6}>
        <TextFieldFormik id={`${sectionKeyPath}.desc`} value={title} label="Display Name" fullWidth disabled />
      </Grid>
      <Grid item xs={6}>
        <TextField id={sectionKeyPath} value={sectionKey} label="Key" fullWidth disabled />
      </Grid>
      <Grid item xs={6}>
        <ArrayMultiselectFieldWithChipsFormik
          id={`${sectionKeyPath}.incident_sub_type_categories`}
          label="Limit to Categories"
          options={categories}
          disabled={isEmpty(categories)}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={12}>
        <Grid container className="flex items-center">
          <Grid item xs={2}>
            <SwitchFormik id={`${sectionKeyPath}.active`} label="Show" className={classes.formsSwitch} hideErrorGap />
          </Grid>
          <Grid item xs={10}>
            {isMandatoryFieldsExists(values as FormikValues) ? (
              <FsTooltip arrow title="One or more inner fields are required">
                <CheckboxFormik
                  id={`${sectionKeyPath}.mandatory`}
                  label="Required field for claim submission"
                  disabled
                />
              </FsTooltip>
            ) : (
              <CheckboxFormik
                id={`${sectionKeyPath}.mandatory`}
                label="Required field for claim submission"
                onChange={() =>
                  setFieldValue(`${sectionKeyPath}.mandatory`, !get(values, `${sectionKeyPath}.mandatory`, false))
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {fieldIds?.map((fieldId) => (
        <FieldConfigurationCard key={fieldId} idPrefix={sectionKeyPath} fieldId={fieldId} disabledCollapse={isHidden} />
      ))}
    </BaseConfigurationSection>
  );
};

export default BaseAdditionalInformationSection;

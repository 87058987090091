import React from 'react';
import axios from 'axios';

import CardDialog from '~/components/CardDialog';
import Button from '~/components/core/Atomic/Buttons/Button';
import UploadImageDialog from '~/components/core/Templates/UploadImageDialog';
import Heading from '~/components/core/TextComponents/Heading';
import Text from '~/components/core/TextComponents/Text';
import TrashIcon from '~/components/icons/TrashIcon';
import NoImagesIllustration from '~/components/illustrations/NoImagesIllustration';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import { reportAxiosError, reportErrorInProductionOrThrow } from '~/Utils';

interface Props {
  backgroundImages: { stored_file_id: string; image_url: string }[];
  reloadData: () => Promise<void>;
}

const DyteBackgroundImageGallery: React.FC<Props> = ({ backgroundImages, reloadData }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const [isUploadImageOpen, setIsUploadImageOpen] = React.useState<boolean>(false);
  const [imageToDelete, setImageToDelete] = React.useState<Props['backgroundImages'][number] | undefined>(undefined);
  const [deleteImageLoading, setDeleteImageLoading] = React.useState<boolean>(false);

  const onFileUpload = async () => {
    await reloadData();
    setIsUploadImageOpen(false);
  };

  const onUploadFinish = async (
    file: File,
    config: { onUploadProgress: (progressEvent: { loaded: number; total: number }) => void }
  ) => {
    const { data } = await axios.post(
      `/api/v1/video_calls_dyte_configuration/organizations/${organization.id}/stored_files/upload_url`,
      {
        file_size: file.size,
        file_type: file.type,
        file_name: file.name,
      }
    );
    const uploadedUrl = data.upload_url;
    const storageFileName = data.storage_filename;
    await axios.put(uploadedUrl, file, config);
    const { data: publicUrl } = await axios.post(
      `/api/v1/video_calls_dyte_configuration/organizations/${organization.id}/stored_files/upload_finished_callback`,

      {
        exposure_ids: [],
        storage_filename: storageFileName,
        file_size: file.size,
        file_type: file.type,
        file_name: file.name,
        last_modified: new Date(file.lastModified).toISOString(),
        type: '',
        summary: '',
        document_name: '',
        document_date: '',
        document_date_received: '',
      }
    );
    return publicUrl.url;
  };

  const onClose = () => {
    setIsUploadImageOpen(false);
  };

  const deleteImage = async () => {
    if (!imageToDelete) {
      reportErrorInProductionOrThrow('Trying to delete image without imageToDelete');
      return;
    }
    setDeleteImageLoading(true);
    try {
      await axios.delete(
        `/api/v1/video_calls_dyte_configuration/organizations/${organization.id}/stored_files/remove_background_image/${imageToDelete.stored_file_id}`
      );
      await reloadData();
      setImageToDelete(undefined);
    } catch (error) {
      reportAxiosError(`Error deleting image`);
    } finally {
      setDeleteImageLoading(false);
    }
  };

  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <Heading variant={Heading.TYPES.H3}>Image background settings</Heading>
          <Button variant="text" color="primary" onClick={() => setIsUploadImageOpen(true)}>
            Upload Image
          </Button>
        </div>
        <Text variant={Text.VARIANTS.XS} colorVariant={Text.COLOR_VARIANTS.PRIMARY} className="opacity-50">
          Choose optional images to use as background images for video calls
        </Text>

        {backgroundImages?.length > 0 ? (
          <div className="mt-20 grid grid-cols-4 gap-16 rounded-lg bg-slate-100 p-16">
            {backgroundImages?.map((image) => (
              <div key={image.stored_file_id}>
                <button
                  onClick={() => {
                    setImageToDelete(image);
                  }}
                  className="cursor-pointer border-0 p-0"
                >
                  <img src={image.image_url} alt="background image" className="max-w-full rounded-md" />
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="mx-auto my-20 flex max-w-xs flex-col items-center justify-center gap-12">
            <NoImagesIllustration />
            <Text variant={Text.VARIANTS.XL}>No Images Yet</Text>
            <Text colorVariant={Text.COLOR_VARIANTS.SECONDARY} weight={Text.WEIGHTS.REGULAR} className="text-center">
              Get started by uploading your first background image
            </Text>
          </div>
        )}
      </div>
      {isUploadImageOpen ? (
        <UploadImageDialog onClose={onClose} onFileUploaded={onFileUpload} onUploadFinish={onUploadFinish} />
      ) : null}
      {imageToDelete ? (
        <CardDialog
          isDialog
          title="View Background Image"
          maxWidth="sm"
          onClose={() => setImageToDelete(undefined)}
          preventClose={deleteImageLoading}
        >
          <div>
            <img
              src={imageToDelete.image_url}
              alt="Image {imageToDelete.stored_file_id}"
              className="max-w-full rounded-md"
            />
            <Button
              startIcon={<TrashIcon iconColor="currentColor" />}
              variant="text"
              color="primary"
              disabled={deleteImageLoading}
              onClick={deleteImage}
            >
              Delete Image
            </Button>
          </div>
        </CardDialog>
      ) : null}
    </>
  );
};

export default DyteBackgroundImageGallery;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@material-ui/core';
import { Formik, useFormikContext } from 'formik';
import _, { cloneDeep, merge } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import InnerCard from '~/components/core/Cards/InnerCard';
import CheckboxWithButtonWrapperFormik from '~/components/core/Formik/CheckboxWithButtonWrapperFormik';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import cn from '~/Utils/cn';

import {
  CONFIGURATION_FEATURES_NAMES,
  PAYMENT_ADDITIONAL_REQUIRED_FIELDS,
  PAYMENT_METHOD_CONFIGURATION_DEFAULT_V3,
  PAYMENT_METHODS_DETAILS_DICT,
} from '../../../../Types';
import { isFeatureEnabled } from '../../../../Utils';
import CardDialog from '../../../CardDialog';
import { ErrorHelperTextFormik } from '../../../core/Formik/ErrorHelperTextFormik';
import ArrayFieldFormik from '../../../core/Formik/NestedFieldFormik/ArrayFieldFormik';
import { PAY_TO_LENGTH_VALIDATION_FIELDS } from '../../../exposures/PaymentRequestContainer/paymentContainerUtils';
import getOneIncMethodSpecificPaymentConfig from '../../../OneInc/MethodSpecificConfiguration';
import TextFieldFormik, { NumericTextFieldFormik } from '../../../TextFieldFormik';
import { CoveragesMultiselectFormik } from '../../../TPA/Coverages/CoveragesMultiselectFormik';
import { useSysconfig } from '../../SystemConfigurationScreen';

import { useStyles } from '../../../../assets/styles';
import styles from './PaymentsConfigurationForm.module.scss';

// The key prop for the following const is used when converting from an object to array and vice versa
const EXPENSES_LABELS_NESTED_FIELD_CONFIG = [
  { id: 'key', label: 'Type Id', type: 'text', disableEditAfterInitialSet: true },
  { id: 'desc', label: 'Type name', type: 'text' },
  { id: 'is_removed', label: 'Is removed', type: 'switch', additionalProps: { hideErrorGap: true } },
];

const INDEMNITY_LABELS_NESTED_FIELD_CONFIG = [
  { id: 'key', label: 'Type Id', type: 'text', disableEditAfterInitialSet: true },
  { id: 'desc', label: 'Type name', type: 'text' },
  { id: 'is_removed', label: 'Is removed', type: 'switch', additionalProps: { hideErrorGap: true } },
];

const INNER_FIELD_INITIAL_VALUES = { key: '', desc: '', is_removed: false };

function VitessePaymentProviderConfig({
  paymentProvidersConfigured,
  currentPaymentMethod,
  requiredFieldsForced,
  setRequiredFieldsForced,
  readOnly,
}) {
  // NOTE: make more generic once we have more payment provider and/or Vitesse supports more methods
  const { isSubmitting, values, setFieldValue, setFieldTouched } = useFormikContext();
  const classes = useStyles();
  const isVitesseEnabled = values.method_configuration.payment_provider === 'vitesse';
  const isCustomMethod = currentPaymentMethod === 'custom_method';
  const isVitesseCustomMethodEnabled = isVitesseEnabled && isCustomMethod;
  const fieldsRequiredForVitesseCustomMethod = React.useMemo(() => ['phone_number', 'email'], []);

  React.useEffect(() => {
    // when editing custom method
    setFieldTouched('method_configuration.payment_provider_specific.vitesse_account_id', true);
    setFieldTouched('method_configuration.payment_provider_specific.vitesse_programme_id', true);
    if (isVitesseCustomMethodEnabled) {
      if (requiredFieldsForced !== fieldsRequiredForVitesseCustomMethod) {
        setRequiredFieldsForced(fieldsRequiredForVitesseCustomMethod);
      }
    }
  }, [
    isVitesseEnabled,
    currentPaymentMethod,
    fieldsRequiredForVitesseCustomMethod,
    setRequiredFieldsForced,
    requiredFieldsForced,
    isVitesseCustomMethodEnabled,
    setFieldTouched,
  ]);

  const handleChange = () => {
    if (isCustomMethod) {
      setRequiredFieldsForced(isVitesseEnabled ? [] : fieldsRequiredForVitesseCustomMethod);
      if (!isVitesseEnabled) {
        fieldsRequiredForVitesseCustomMethod.forEach((requiredFieldKey) => {
          setFieldValue(`method_configuration.required_fields.${requiredFieldKey}`, true);
        });
      }
    }

    setFieldValue('method_configuration.payment_provider', isVitesseEnabled ? '' : 'vitesse');
  };

  if (!paymentProvidersConfigured.includes('vitesse')) {
    return null;
  }

  if (!['uk_bank_transfer', 'custom_method'].includes(currentPaymentMethod)) {
    return null;
  }

  return (
    <>
      <Grid container spacing={1} className={styles.section}>
        <Grid item xs={12}>
          <div className={cn(styles.switchContainer, styles.section)}>
            <Switch
              checked={values.method_configuration.payment_provider === 'vitesse'}
              className={classes.formsSwitch}
              size="small"
              onChange={handleChange}
              disabled={readOnly || isSubmitting}
            />
            <span>Enable Vitesse as payment provider</span>
          </div>
        </Grid>
        {isVitesseCustomMethodEnabled && (
          <>
            <Grid item xs={6}>
              <TextFieldFormik
                className={classes.textField}
                id="method_configuration.payment_provider_specific.vitesse_account_id"
                label="Account ID"
                disabled={readOnly || isSubmitting}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldFormik
                className={classes.textField}
                id="method_configuration.payment_provider_specific.vitesse_programme_id"
                label="Progremme ID"
                disabled={readOnly || isSubmitting}
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

VitessePaymentProviderConfig.propTypes = {
  paymentProvidersConfigured: PropTypes.array,
  currentPaymentMethod: PropTypes.string.isRequired,
  requiredFieldsForced: PropTypes.array.isRequired,
  setRequiredFieldsForced: PropTypes.func.isRequired,
  readOnly: PropTypes.any,
};

const PaymentsConfigurationForm = ({
  isDialogOpen,
  readOnly,
  onClose,
  handleFormSubmit,
  configValues,
  isHomeLobEnabledForOrg,
  paymentMethodName,
  isLastEnabledPaymentMethod,
  isDialog = true,
  paymentProvidersConfigured,
}) => {
  const classes = useStyles();
  const { organization } = useSysconfig();
  const [requiredFieldsForced, setRequiredFieldsForced] = React.useState([]);

  const isConfigurableCoveragesEnabled = isFeatureEnabled(
    organization,
    CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_COVERAGES
  );

  const isConfigurableFnolEnabled = isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);
  const isForceMortgageeEnabled = PAYMENT_METHODS_DETAILS_DICT[paymentMethodName]?.include_force_mortgagee_option;
  const shouldDisplayForceMortgageeOptions =
    isHomeLobEnabledForOrg && isForceMortgageeEnabled && !isConfigurableFnolEnabled;

  const getDefaultMethodSpecificPaymentConfig = () => {
    const methodSpecificInitialValues = {};
    const methodSpecificValidationSchema = Yup.object();
    const MethodSpecificPaymentConfigComponent = null;
    return {
      methodSpecificInitialValues,
      methodSpecificValidationSchema,
      MethodSpecificPaymentConfigComponent,
    };
  };

  const isOneIncPayment = paymentMethodName === 'one_inc';
  const getMethodSpecificPaymentConfig = isOneIncPayment
    ? getOneIncMethodSpecificPaymentConfig
    : getDefaultMethodSpecificPaymentConfig;
  const { methodSpecificInitialValues, methodSpecificValidationSchema, MethodSpecificPaymentConfigComponent } =
    getMethodSpecificPaymentConfig();

  const ADDITIONAL_SETTINGS_SWITCHES = [
    {
      title: '',
      isActive: () => true,
      switches: [
        { id: 'enable_record_only', text: 'Include "Record only" checkbox' },
        { id: 'enable_reissued', text: 'Include "Reissue payment" checkbox' },
        { id: 'enable_documents', text: 'Include Document Attachments' },
      ],
    },
    {
      title: '',
      isActive: (paymentMethod) => PAYMENT_METHODS_DETAILS_DICT[paymentMethod]?.reference?.should_include,
      switches: [
        {
          id: 'prefill_indemnity_reference_with_claim_number',
          text: 'Pre-fill indemnity payment reference with "Claim number"',
        },
        {
          id: 'prefill_expenses_reference_with_invoice_number',
          text: 'For expense payments use "Invoice number" as the reference by default',
        },
      ],
    },
    {
      title: 'Only for home LOB:',
      isActive: () => shouldDisplayForceMortgageeOptions,
      switches: [{ id: 'home_only_should_force_mortgagee', text: 'Set mortgagee as a payee in payment request:' }],
    },
  ];

  const getInitialValues = () => {
    const defaultConfig = cloneDeep(PAYMENT_METHOD_CONFIGURATION_DEFAULT_V3);
    const defaultMethodSpecificConfig = { method_configuration: { method_specific: methodSpecificInitialValues } };
    if (PAYMENT_METHODS_DETAILS_DICT[paymentMethodName]?.always_enable_documents) {
      configValues.method_configuration.enable_documents = true;
    }
    return merge(defaultConfig, defaultMethodSpecificConfig, configValues);
  };

  const validateMailEnabledForCurrentPaymentMethod = (currentPaymentMethod) => {
    return Boolean(
      currentPaymentMethod && PAYMENT_METHODS_DETAILS_DICT[currentPaymentMethod]?.mail_to_contact?.should_include
    );
  };
  const isUsingCustomIndemnityLabels = (values) => values.method_configuration.use_indemnity_labels;
  const isUsingCustomExpensesLabels = (values) => values.method_configuration.use_expenses_labels;

  const isCustomMethod = PAYMENT_METHODS_DETAILS_DICT[paymentMethodName]?.is_custom_method;
  const labelsNestedScheme = Yup.object().shape({
    key: Yup.string().required('Required'),
    desc: Yup.string().required('Required'),
    is_removed: Yup.boolean(),
  });

  const baseConfigValidationScheme = Yup.object().shape({
    mail_to_limits: Yup.object().shape(
      Object.fromEntries(PAY_TO_LENGTH_VALIDATION_FIELDS.map(({ maxCharsKey }) => [maxCharsKey, Yup.number()]))
    ),
    enable_record_only: Yup.boolean(),
    enable_reissued: Yup.boolean(),
    use_expenses_labels: Yup.boolean(),
    expenses_labels: Yup.array()
      .of(labelsNestedScheme)
      .nullable()
      .when('use_expenses_labels', {
        is: true,
        then: (schema) =>
          schema.test(
            'is-valid',
            'At least 1 active label is required',
            (value) => !value || value.filter(({ is_removed }) => !is_removed).length > 0
          ),
        otherwise: (schema) => schema,
      }),
    use_indemnity_labels: Yup.boolean(),
    indemnity_labels: Yup.array()
      .of(labelsNestedScheme)
      .nullable()
      .when('use_indemnity_labels', {
        is: true,
        then: (schema) =>
          schema.test(
            'is-valid',
            'At least 1 active label is required',
            (value) => !value || value.filter(({ is_removed }) => !is_removed).length > 0
          ),
        otherwise: (schema) => schema,
      }),
    prefill_indemnity_reference_with_claim_number: Yup.boolean(),
    prefill_expenses_reference_with_invoice_number: Yup.boolean(),
    home_only_should_force_mortgagee: Yup.boolean(),
    force_mortgagee_options: Yup.object().when('home_only_should_force_mortgagee', {
      is: true,
      then: (schema) =>
        schema.shape({
          coverage_keys: Yup.array().of(Yup.string()).min(1, 'At least one coverage is required'),
          amount: Yup.number().required('Required'),
        }),
      otherwise: (schema) => schema,
    }),
    required_fields: Yup.lazy((value) => {
      const schema = {};

      if (value) {
        Object.keys(value).map((key) => (schema[key] = Yup.boolean()));
      }

      return Yup.object().shape(schema);
    }),
    method_specific: methodSpecificValidationSchema,
    payment_provider_specific: Yup.object().when('payment_provider', {
      is: 'vitesse',
      then: (schema) =>
        schema.shape({
          vitesse_account_id: Yup.string().required('Required'),
          vitesse_programme_id: Yup.string().required('Required'),
        }),
      otherwise: (schema) => schema,
    }),
  });

  return (
    <Formik
      initialValues={getInitialValues()}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await handleFormSubmit(values, paymentMethodName);
        } catch (e) {
          setSubmitting(false);
        }
      }}
      validationSchema={Yup.object().shape({
        payment_method_external_id: isCustomMethod
          ? Yup.string()
              .matches(/^[a-zA-Z0-9_-]*$/, 'Can only contain letters, numbers and the following special characters: _-')
              .required('Required')
          : null,
        payment_method_display_name: isCustomMethod ? Yup.string().required('Required') : undefined,
        method_configuration: baseConfigValidationScheme,
      })}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values, setFieldValue } = formikProps;
        const currentPaymentMethod = paymentMethodName;

        return (
          <CardDialog
            isDialog={isDialog}
            open={isDialogOpen}
            title={`${PAYMENT_METHODS_DETAILS_DICT[currentPaymentMethod].display_name} Method Configuration`}
            onClose={onClose}
            maxWidth="md"
          >
            <Grid container spacing={2} className={styles.mainContainer}>
              <div className={cn(styles.switchContainer, styles.section)}>
                <Switch
                  checked={!values.is_disabled}
                  className={classes.formsSwitch}
                  size="small"
                  onChange={() => {
                    setFieldValue('is_disabled', !values.is_disabled);
                  }}
                  disabled={isLastEnabledPaymentMethod || isSubmitting}
                />
                <span>{values.is_disabled ? 'Disabled' : 'Enabled'}</span>
              </div>

              {MethodSpecificPaymentConfigComponent && (
                <Grid container spacing={4} className={styles.section}>
                  <SectionHeading
                    title={`${PAYMENT_METHODS_DETAILS_DICT[currentPaymentMethod].display_name} Method Details`}
                    subtitle=""
                  />
                  <MethodSpecificPaymentConfigComponent />
                </Grid>
              )}

              {PAYMENT_METHODS_DETAILS_DICT[currentPaymentMethod].is_custom_method && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      className={classes.textField}
                      id="payment_method_external_id"
                      label="Method ID"
                      disabled={readOnly || isSubmitting}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldFormik
                      className={classes.textField}
                      id="payment_method_display_name"
                      label="Method Name"
                      disabled={readOnly || isSubmitting}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}

              <VitessePaymentProviderConfig
                paymentProvidersConfigured={paymentProvidersConfigured}
                currentPaymentMethod={currentPaymentMethod}
                requiredFieldsForced={requiredFieldsForced}
                setRequiredFieldsForced={setRequiredFieldsForced}
                readOnly={readOnly}
              />

              {_.difference(
                Object.keys(PAYMENT_ADDITIONAL_REQUIRED_FIELDS),
                PAYMENT_METHODS_DETAILS_DICT[currentPaymentMethod].excluded_required_fields || []
              ).length > 0 && (
                <InnerCard className={styles.section}>
                  <SectionHeading
                    title="Required Fields"
                    subtitle="Select which field is mandatory to fill in when creating a new payment. Optional to include both fields."
                  />
                  <Grid container spacing={1} className={styles.section}>
                    {Object.keys(PAYMENT_ADDITIONAL_REQUIRED_FIELDS).map((required_field) => (
                      <>
                        {!(PAYMENT_METHODS_DETAILS_DICT[currentPaymentMethod].excluded_required_fields || []).includes(
                          required_field
                        ) && (
                          <Grid key={required_field} item xs={3}>
                            <CheckboxWithButtonWrapperFormik
                              id={`method_configuration.required_fields.${required_field}`}
                              text={PAYMENT_ADDITIONAL_REQUIRED_FIELDS[required_field].desc}
                              disabled={readOnly || isSubmitting || requiredFieldsForced.includes(required_field)}
                              className={styles.checkbox}
                            />
                          </Grid>
                        )}
                      </>
                    ))}
                  </Grid>
                </InnerCard>
              )}

              {currentPaymentMethod && (
                <>
                  {validateMailEnabledForCurrentPaymentMethod(currentPaymentMethod) && (
                    <Grid container spacing={4} className={styles.section}>
                      <SectionHeading title="Mail fields max lengths" subtitle="" />
                      {PAY_TO_LENGTH_VALIDATION_FIELDS.map(({ maxCharsKey, description }) => (
                        <Grid item xs={6} key={String(maxCharsKey)}>
                          <NumericTextFieldFormik
                            id={`method_configuration.mail_to_limits.${maxCharsKey}`}
                            label={description}
                            showOnly={readOnly || isSubmitting}
                            fullWidth
                          />
                          <ErrorHelperTextFormik id={`method_configuration.mail_to_limits.${maxCharsKey}`} />
                        </Grid>
                      ))}
                    </Grid>
                  )}

                  <InnerCard className={styles.section}>
                    <SectionHeading title="Expenses Payment Types" subtitle="" className={styles.section} />
                    <Grid item xs={12}>
                      <SwitchFormik
                        id="method_configuration.use_expenses_labels"
                        label="Set expenses types"
                        showOnly={readOnly || isSubmitting}
                        color="primary"
                        className={classes.formsSwitch}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {isUsingCustomExpensesLabels(values) ? (
                        <>
                          <ArrayFieldFormik
                            fieldId="method_configuration.expenses_labels"
                            label="Expenses labels"
                            innerObjectConfig={EXPENSES_LABELS_NESTED_FIELD_CONFIG}
                            addNewItemButtonText="Add new expenses label +"
                            disabled={readOnly || isSubmitting}
                            initialNewItemValues={INNER_FIELD_INITIAL_VALUES}
                            allowDeletingInitialValues={false}
                          />
                          <ErrorHelperTextFormik id="method_configuration.expenses_labels" withoutChildren />
                        </>
                      ) : null}
                    </Grid>
                  </InnerCard>

                  <InnerCard className={styles.section}>
                    <SectionHeading
                      title="Indemnity Payment Types"
                      subtitle="Optional to add labels to Indemnity payments, will appear in as a dropdown when making a payment"
                    />
                    <Grid item xs={12}>
                      <SwitchFormik
                        id="method_configuration.use_indemnity_labels"
                        label="Set indemnity types"
                        showOnly={readOnly || isSubmitting}
                        color="primary"
                        className={classes.formsSwitch}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {isUsingCustomIndemnityLabels(values) && (
                        <>
                          <ArrayFieldFormik
                            fieldId="method_configuration.indemnity_labels"
                            label="Indemnity labels"
                            innerObjectConfig={INDEMNITY_LABELS_NESTED_FIELD_CONFIG}
                            addNewItemButtonText="Add new indemnity label +"
                            disabled={readOnly || isSubmitting}
                            initialNewItemValues={INNER_FIELD_INITIAL_VALUES}
                            allowDeletingInitialValues={false}
                          />
                          <ErrorHelperTextFormik id="method_configuration.indemnity_labels" withoutChildren />
                        </>
                      )}
                    </Grid>
                  </InnerCard>

                  <div className={styles.section}>
                    <SectionHeading title="Additional Settings" />
                    {ADDITIONAL_SETTINGS_SWITCHES.map(({ title, switches, isActive }, idx) => (
                      <Fragment key={idx}>
                        {isActive(currentPaymentMethod) && (
                          <Grid item xs={12} key={title}>
                            <Typography variant="subtitle1">{title}</Typography>
                            {switches.map(({ id, text }) => (
                              <Grid item xs={12} key={id}>
                                <SwitchFormik
                                  id={`method_configuration.${id}`}
                                  label={text}
                                  key={id}
                                  showOnly={readOnly}
                                  color="primary"
                                  className={classes.formsSwitch}
                                  disabled={
                                    isSubmitting ||
                                    (id === 'enable_documents' &&
                                      PAYMENT_METHODS_DETAILS_DICT[currentPaymentMethod]?.always_enable_documents)
                                  }
                                />
                              </Grid>
                            ))}
                          </Grid>
                        )}
                      </Fragment>
                    ))}
                  </div>

                  {shouldDisplayForceMortgageeOptions && (
                    <Grid direction="row" container spacing={2} style={{ margin: '-14px 0 20px 12px' }}>
                      <Grid item xs={6}>
                        <NumericTextFieldFormik
                          id="method_configuration.force_mortgagee_options.amount"
                          label="Amount above"
                          fullWidth
                          disabled={readOnly || !isHomeLobEnabledForOrg || isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: '-3px' }}>
                        <CoveragesMultiselectFormik
                          lobs={['home_claim']}
                          label="For Coverages"
                          subOrganizationIds={[]}
                          coveragesFieldId="method_configuration.force_mortgagee_options.coverage_keys"
                          disabled={readOnly || !isHomeLobEnabledForOrg || isSubmitting}
                          disableGeneral={true}
                          useOrgLevelCoveragesOnly={isConfigurableCoveragesEnabled}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {!readOnly && (
                    <div className={classes.buttonsContainer}>
                      <Button
                        variant="contained"
                        onClick={onClose}
                        disabled={isSubmitting}
                        className={classes.cancelButtonWithRoundMargin}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                        className={classes.button}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </>
              )}
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

PaymentsConfigurationForm.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  configValues: PropTypes.object.isRequired,
  isHomeLobEnabledForOrg: PropTypes.bool.isRequired,
  paymentMethodName: PropTypes.string,
  isLastEnabledPaymentMethod: PropTypes.bool,
  isDialog: PropTypes.bool,
  paymentProvidersConfigured: PropTypes.array,
};

const SectionHeading = ({ title, subtitle, className }) => {
  return (
    <Grid item xs={12} className={className}>
      <Typography variant="subtitle1">{title}</Typography>
      {subtitle && <Typography variant="caption">{subtitle}</Typography>}
    </Grid>
  );
};

SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string,
};

export default PaymentsConfigurationForm;

import React from 'react';
import axios from 'axios';
import type { FormikValues } from 'formik';
import { Formik } from 'formik';
import { get } from 'lodash';

import mixpanel from '~/components/CmsMain/mixpanel';
import { FsIndexPanel, LoadingSwitch } from '~/components/core';
import SectionWrapper from '~/components/Fnol/NewFnolUI/SectionWrapper';
import ConfigurationBreadcrumbs from '~/components/SystemConfiguration/ConfigurationPage/ConfigurationBreadcrumbs';
import { setContactConfiguredFieldsKeysInValues } from '~/components/SystemConfiguration/ConfigurationPage/utils';
import FnolPrompt from '~/components/SystemConfiguration/FnolConfiguration/FnolConfigurationScreen/FnolPrompt/FnolPrompt';
import Header from '~/components/SystemConfiguration/FnolConfiguration/FnolConfigurationScreen/Header/Header';
import fnolConfigurationSchema from '~/components/SystemConfiguration/FnolConfiguration/fnolConfigurationValidationSchema';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '~/components/useDataFetcher';
import { FNOL_MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { reportAxiosError } from '~/Utils';

import type { ConfigurationScreenProps } from '../../ConfigurationPage/types';

import CoveredVehicleSection from './Sections/CoveredVehicleSection';
import GeneralCoveredObjectSection from './Sections/GeneralCoveredObjectSection';
import PolicyDetailsSection from './Sections/PolicyDetailsSection';
import copies from './copies.json';
import PolicyConfigurationFooter from './PolicyConfigurationFooter';

const getCoveredObjectSection = (fnolConfiguration: { lob: string }) => {
  if (fnolConfiguration?.lob === 'auto_claim') {
    return [
      {
        id: 'covered_vehicle',
        title: 'Involved Property',
        node: <CoveredVehicleSection />,
      },
    ];
  }

  const coveredObject = get(fnolConfiguration, 'configuration.policy.covered_objects[0]', {});
  if (!coveredObject) return [];

  return [
    {
      id: 'covered_object',
      title: coveredObject.desc,
      node: <GeneralCoveredObjectSection />,
    },
  ];
};

const PolicyConfigurationScreen: React.FC<ConfigurationScreenProps> = ({
  incidentConfigurationId,
  subOrgId,
  onCancel,
  onSubmit,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();

  const incidentConfigurationsRoute = `/sysconfig/api/v1/organizations/${organization.id}/incident_configurations/${incidentConfigurationId}`;
  const { isLoading, isError, data: fnolConfiguration = {} } = useDataFetcher(`${incidentConfigurationsRoute}`, {});

  const trackMixpanelEvent = () => {
    mixpanel.track(FNOL_MIXPANEL_EVENTS.SAVE_POLICY_CONFIGURATION_CLICKED, {
      incident_configuration_id: incidentConfigurationId,
      sub_organization_id: subOrgId,
    });
  };

  const handleSubmitFnolConfiguration = async (values: FormikValues) => {
    try {
      trackMixpanelEvent();
      values = setContactConfiguredFieldsKeysInValues(values);
      await axios.patch(incidentConfigurationsRoute, values);
      await onSubmit();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <Formik
        initialValues={{ ...fnolConfiguration, sub_organization_id: subOrgId }}
        enableReinitialize
        validationSchema={fnolConfigurationSchema(fnolConfiguration)}
        onSubmit={async (values, formikProps) => {
          const { setSubmitting } = formikProps;
          try {
            setSubmitting(true);
            await handleSubmitFnolConfiguration(values);
          } catch (error) {
            setSubmitting(false);
          }
        }}
      >
        <>
          <FnolPrompt />
          <div className="fixed z-10 w-full bg-slate-200 p-12">
            <ConfigurationBreadcrumbs
              onCancel={onCancel}
              externalAdminRoute="/policy_configuration"
              configurationName="Policy Configuration"
            />
          </div>
          {/* Gap between start of sections */}
          {Array.from(Array(2).keys()).map((item, index) => (
            <SectionWrapper key={index} />
          ))}
          <Header title={copies.header.title} subtitle={copies.header.subtitle} />
          <div className="bg-slate-200 pt-32">
            <div>
              <FsIndexPanel
                sections={[
                  {
                    id: 'policy_details',
                    title: 'Policy Details',
                    node: <PolicyDetailsSection />,
                    isCollapsible: false,
                    children: [],
                  },
                  ...getCoveredObjectSection(fnolConfiguration),
                ]}
                panelTitle="Policy Content"
                SectionWrapperComponent={SectionWrapper}
                sectionContainerClassName="scroll-mt-40"
                handleScrollTopOffset={40}
              />
              {/* Gap between end of sections and the footer */}
              {Array.from(Array(30).keys()).map((item, index) => (
                <SectionWrapper key={index} />
              ))}
            </div>
          </div>
          <PolicyConfigurationFooter onCancel={onCancel} />
        </>
      </Formik>
    </LoadingSwitch>
  );
};

export default PolicyConfigurationScreen;

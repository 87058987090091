import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, getIn, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import CheckboxFormik from '~/components/CheckboxFormik';
import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import ButtonsContainer from '~/components/core/Atomic/Buttons/ButtonsContainer';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Tooltip from '~/components/core/Atomic/Tooltip';
import CancelButton from '~/components/core/Buttons/CancelButton';
import useFormikChangeListener from '~/components/core/Formik/FormikChangeListener';
import LobMultiSelectWithChipsFormik, {
  isAllLobsSelected,
} from '~/components/core/Formik/LobMultiSelectWithChipsFormik';
import SubOrgMultiSelectWithChipsFormik, {
  isAllSubOrganizationsSelected,
} from '~/components/core/Formik/SubOrgMultiSelectWithChipsFormik';
import Heading from '~/components/core/TextComponents/Heading';
import InfoIcon from '~/components/icons/InfoIcon';
import COMMUNICATION_TEMPLATE_ACTION from '~/server_shared/generated-types/COMMUNICATION_TEMPLATE_ACTION';
import { reportAxiosError } from '~/Utils';
import cn from '~/Utils/cn';

import CardDialog from '../../../CardDialog';
import useOrganization from '../../../OrganizationContext';
import TextFieldFormik from '../../../TextFieldFormik';
import Conditions, { CONDITION_FIELD_IDS } from '../Conditions/Conditions';

import { recipientBaseKey, recipientByScope, senderBaseKey, senderByScope } from './types';

const FIELD_IDS = {
  ID: 'id',
  DISPLAY_NAME: 'display_name',
  SUB_ORGANIZATION_IDS: 'sub_organization_ids',
  LOBS: 'lobs',
  ALL_LOBS: 'is_all_lobs',
  CONDITIONS: 'conditions',
  COMMUNICATION_TYPE: 'channel',
  COMMUNICATION_RECIPIENT: 'recipient',
  COMMUNICATION_SENDER: 'sender_type',
  COMMUNICATION_TEMPLATE: 'generic_template_id',
  EVENT_KEY: 'event_key',
  IS_SUGGESTION: 'is_suggestion',
};

const AddOrEditRuleDialog = ({ onSubmit, supportedEvents, onCancel, rule }) => {
  const { subOrganizationEnabled } = useOrganization();

  return (
    <Formik
      initialValues={{
        [FIELD_IDS.ID]: rule?.id || '',
        [FIELD_IDS.DISPLAY_NAME]: rule?.display_name ?? '',
        [FIELD_IDS.SUB_ORGANIZATION_IDS]: rule
          ? rule.is_all_sub_orgs
            ? [SubOrgMultiSelectWithChipsFormik.ALL_OPTION.id]
            : rule.sub_organization_ids
          : [],
        [FIELD_IDS.LOBS]: rule?.lobs ?? [],
        [FIELD_IDS.ALL_LOBS]: rule?.is_all_lobs ?? false,
        [FIELD_IDS.CONDITIONS]: rule?.conditions ?? [],
        [FIELD_IDS.EVENT_KEY]: rule?.event_key ?? '',
        [FIELD_IDS.COMMUNICATION_TEMPLATE]: rule?.generic_template_id ?? '',
        [FIELD_IDS.COMMUNICATION_RECIPIENT]: rule?.recipient ?? '',
        [FIELD_IDS.COMMUNICATION_SENDER]: rule?.sender_type ?? '',
        [FIELD_IDS.COMMUNICATION_TYPE]: rule?.channel ?? '',
        [FIELD_IDS.IS_SUGGESTION]: !!rule?.is_suggestion,
      }}
      validationSchema={Yup.object().shape({
        [FIELD_IDS.DISPLAY_NAME]: Yup.string().required('Required'),
        [FIELD_IDS.EVENT_KEY]: Yup.string().required('Required'),
        [FIELD_IDS.COMMUNICATION_TEMPLATE]: Yup.string().required('Required'),
        [FIELD_IDS.COMMUNICATION_RECIPIENT]: Yup.string().required('Required'),
        [FIELD_IDS.COMMUNICATION_SENDER]: Yup.string().required('Required'),
        [FIELD_IDS.COMMUNICATION_TYPE]: Yup.string().required('Required'),
        [FIELD_IDS.SUB_ORGANIZATION_IDS]: subOrganizationEnabled
          ? Yup.array().of(Yup.number()).min(1, 'Required').required('Required')
          : Yup.array().of(Yup.number()).strip(),
        [FIELD_IDS.LOBS]: Yup.array().of(Yup.string()).min(1, 'Required').required('Required'),
        [FIELD_IDS.CONDITIONS]: Yup.array().of(
          Yup.object().shape({
            [CONDITION_FIELD_IDS.CONDITION_KEY]: Yup.string().required('Required'),
            [CONDITION_FIELD_IDS.OPERATOR_1]: Yup.string().required('Required'),
            [CONDITION_FIELD_IDS.OPERATOR_2]: Yup.string().nullable(),
            [CONDITION_FIELD_IDS.VALUE_1]: Yup.mixed().required('Required'),
            [CONDITION_FIELD_IDS.VALUE_2]: Yup.mixed().nullable(),
          })
        ),
        [FIELD_IDS.IS_SUGGESTION]: Yup.bool(),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          const isAllSubOrganizations = isAllSubOrganizationsSelected(values[FIELD_IDS.SUB_ORGANIZATION_IDS]);
          await onSubmit({
            ...values,
            is_all_sub_orgs: isAllSubOrganizations,
            [FIELD_IDS.SUB_ORGANIZATION_IDS]: isAllSubOrganizations ? [] : values[FIELD_IDS.SUB_ORGANIZATION_IDS],
            context: supportedEvents[values[FIELD_IDS.EVENT_KEY]].scope,
          });
          onCancel();
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {() => {
        return <AddRuleInner onCancel={onCancel} supportedEvents={supportedEvents} />;
      }}
    </Formik>
  );
};

AddOrEditRuleDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  supportedEvents: PropTypes.object,
  rule: PropTypes.object,
};

const AddRuleInner = ({ supportedEvents, onCancel }) => {
  const { subOrganizationEnabled, subOrganizations } = useOrganization();
  const { isSubmitting, handleSubmit, values, setFieldValue } = useFormikContext();
  const [isLoadingConditions, setIsLoadingConditions] = useState(false);
  const { isFetchingTemplates, templates } = useTemplates({ supportedEvents });

  const classes = useStyles();

  const isDisabled = isSubmitting || isLoadingConditions;
  const isTemplateValuesFilled = !isEmpty(values[FIELD_IDS.LOBS]) && !isEmpty(values[FIELD_IDS.COMMUNICATION_TYPE]);

  const subOrganizationsValue = useMemo(() => values[FIELD_IDS.SUB_ORGANIZATION_IDS], [values]);

  const isEditing = !!getIn(values, FIELD_IDS.ID);
  const scope = supportedEvents[values[FIELD_IDS.EVENT_KEY]]?.scope;

  // reset all lobs when sub org changes
  useFormikChangeListener({
    listenForKeys: [FIELD_IDS.SUB_ORGANIZATION_IDS],
    onChange: () => {
      setFieldValue(FIELD_IDS.LOBS, []);
      setFieldValue(FIELD_IDS.ALL_LOBS, false);
    },
    runOnFirstRender: false,
  });

  // reset channel when lobs updated
  useFormikChangeListener({
    listenForKeys: [FIELD_IDS.LOBS, FIELD_IDS.ALL_LOBS, FIELD_IDS.COMMUNICATION_TYPE],
    onChange: () => {
      if (!values[FIELD_IDS.ALL_LOBS] && !isAllLobsSelected(values[FIELD_IDS.LOBS])) {
        setFieldValue(FIELD_IDS.COMMUNICATION_TEMPLATE, '');
      }
    },
    runOnFirstRender: false,
  });

  // reset channel when channel updated
  useFormikChangeListener({
    listenForKeys: [FIELD_IDS.COMMUNICATION_TYPE],
    onChange: () => {
      setFieldValue(FIELD_IDS.COMMUNICATION_TEMPLATE, '');
    },
    runOnFirstRender: false,
  });

  useFormikChangeListener({
    listenForKeys: [FIELD_IDS.EVENT_KEY],
    onChange: () => {
      setFieldValue(FIELD_IDS.COMMUNICATION_SENDER, '');
      setFieldValue(FIELD_IDS.COMMUNICATION_RECIPIENT, '');
    },
    runOnFirstRender: false,
  });

  const subOrganizationIds = subOrganizationEnabled
    ? !isAllSubOrganizationsSelected(subOrganizationsValue)
      ? subOrganizationsValue
      : subOrganizations.map(({ id }) => id)
    : null;

  const lobs = getIn(values, FIELD_IDS.LOBS);
  const eventKey = getIn(values, FIELD_IDS.EVENT_KEY);
  return (
    <CardDialog
      isDialog
      onClose={onCancel}
      maxWidth="md"
      fullWidth
      title={`${isEditing ? 'Edit' : 'Add'} Communication`}
    >
      <AlertBanner
        className="mb-4"
        alertType="info"
        note="Editing the sub organizations and line of businesses will clear all conditions."
        title="Please Note"
        withIcon
      />
      <TextFieldFormik
        id={FIELD_IDS.DISPLAY_NAME}
        label="Rule Name"
        className={cn(classes.textField, 'mb-20')}
        fullWidth
        disabled={isDisabled}
      />
      <div className="flex flex-row items-stretch px-4 py-20">
        <CheckboxFormik id={FIELD_IDS.IS_SUGGESTION} label="Is Suggestion" />
        <Tooltip title="Suggestion will create a notification for the sender with the templated communication">
          <span className="flex w-16 items-center justify-center">
            <InfoIcon size={16} iconColor="currentColor" />
          </span>
        </Tooltip>
      </div>
      <div className="rounded-md border border-solid border-slate-600 px-20 pb-20 pt-16">
        <Heading variant={Heading.TYPES.H3} className="pb-12">
          Context
        </Heading>
        {subOrganizationEnabled ? (
          <div className="mb-12">
            <SubOrgMultiSelectWithChipsFormik
              id={FIELD_IDS.SUB_ORGANIZATION_IDS}
              label="Sub Organizations"
              showOnly={isSubmitting}
              disabled={isSubmitting}
            />
          </div>
        ) : null}
        <div className="mb-4">
          <LobMultiSelectWithChipsFormik
            lobsFieldId={FIELD_IDS.LOBS}
            allLobsFieldId={FIELD_IDS.ALL_LOBS}
            {...(subOrganizationEnabled
              ? {
                  subOrganizationIds,
                  subOrgFieldId: FIELD_IDS.SUB_ORGANIZATION_IDS,
                  shouldResetOnSubOrgChange: true,
                  disabled: isEmpty(subOrganizationsValue),
                }
              : {})}
          />
        </div>
      </div>
      <TextFieldFormik
        id={FIELD_IDS.EVENT_KEY}
        label="Event"
        className={cn(classes.textField, 'mb-20 w-1/2')}
        fullWidth
        disabled={isSubmitting}
        select
      >
        {Object.keys(supportedEvents).map((key) => (
          <MenuItem key={key} value={key}>
            {supportedEvents[key].desc}
          </MenuItem>
        ))}
      </TextFieldFormik>
      <div className="rounded-md border border-solid border-slate-600 px-20 pb-20 pt-16">
        <Heading variant={Heading.TYPES.H3}>Details</Heading>
        <div className="grid grid-cols-2 gap-x-16">
          <TextFieldFormik
            id={FIELD_IDS.COMMUNICATION_TYPE}
            label="Communication Type"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
            select
          >
            {Object.keys(COMMUNICATION_TEMPLATE_ACTION.CHANNEL).map((channelKey) => (
              <MenuItem key={channelKey} value={channelKey}>
                {COMMUNICATION_TEMPLATE_ACTION.CHANNEL[channelKey]}
              </MenuItem>
            ))}
          </TextFieldFormik>
          <TextFieldFormik
            id={FIELD_IDS.COMMUNICATION_RECIPIENT}
            label="Recipient"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting || !scope}
            select
          >
            {scope
              ? recipientByScope[scope].map((recipientKey) => (
                  <MenuItem key={recipientKey} value={recipientKey}>
                    {recipientBaseKey[recipientKey]}
                  </MenuItem>
                ))
              : null}
          </TextFieldFormik>
          <TextFieldFormik
            id={FIELD_IDS.COMMUNICATION_TEMPLATE}
            label="Template"
            className={classes.textField}
            fullWidth
            disabled={isFetchingTemplates || !isTemplateValuesFilled}
            select
          >
            {templates.map(({ id, template_name }) => (
              <MenuItem key={id} value={id}>
                {template_name}
              </MenuItem>
            ))}
          </TextFieldFormik>
          <TextFieldFormik
            id={FIELD_IDS.COMMUNICATION_SENDER}
            label="Sender"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting || !scope}
            select
          >
            {scope
              ? senderByScope[scope].map((senderKey) => (
                  <MenuItem key={senderKey} value={senderKey}>
                    {senderBaseKey[senderKey]}
                  </MenuItem>
                ))
              : null}
          </TextFieldFormik>
        </div>
      </div>
      <div className="px-4 py-20">
        <Conditions
          automation_type="automatic_communications"
          lobs={lobs}
          eventKey={eventKey}
          conditionsFieldId={FIELD_IDS.CONDITIONS}
          disabled={isDisabled}
          setIsLoading={setIsLoadingConditions}
          shouldLoadConditions={!!(lobs && lobs.length > 0 && eventKey)}
          loadConditionsFieldsIds={[FIELD_IDS.LOBS, FIELD_IDS.EVENT_KEY, FIELD_IDS.ALL_LOBS]}
          {...(subOrganizationEnabled
            ? {
                subOrganizationIds,
              }
            : {})}
        />
      </div>

      <ButtonsContainer>
        <CancelButton disabled={isDisabled} onClick={onCancel} />
        <Button variant="contained" color="primary" disabled={isDisabled} onClick={handleSubmit}>
          Save
        </Button>
      </ButtonsContainer>
    </CardDialog>
  );
};

AddRuleInner.propTypes = {
  onCancel: PropTypes.func.isRequired,
  supportedEvents: PropTypes.object,
};

const useTemplates = ({ supportedEvents }) => {
  const { values } = useFormikContext();
  const { organizationId, subOrganizationEnabled } = useOrganization();
  const [isFetchingTemplates, setIsFetchingTemplates] = useState(false);
  const [templates, setTemplates] = useState([]);

  const subOrganizationsValue = useMemo(() => values[FIELD_IDS.SUB_ORGANIZATION_IDS], [values]);
  const lobsValue = useMemo(() => values[FIELD_IDS.LOBS], [values]);
  const channelValue = useMemo(() => values[FIELD_IDS.COMMUNICATION_TYPE], [values]);
  const isAllSubOrganizations = useMemo(
    () => subOrganizationEnabled && isAllSubOrganizationsSelected(subOrganizationsValue),
    [subOrganizationEnabled, subOrganizationsValue]
  );
  const eventKey = values[FIELD_IDS.EVENT_KEY];

  useEffect(() => {
    const getTemplates = async () => {
      try {
        setIsFetchingTemplates(true);
        const { data } = await axios.get(`/api/v1/generic_templates/organization/${organizationId}`, {
          params: {
            template_type: channelValue.toLowerCase(),
            is_all_suborgs: isAllSubOrganizations,
            sub_organization_ids: subOrganizationsValue,
            lobs: lobsValue,
            is_enabled: true,
            is_claim_level: supportedEvents[eventKey]?.scope !== 'exposure',
          },
        });
        setTemplates(data.generic_templates_metadata);
      } catch (error) {
        await reportAxiosError(error);
      }
      setIsFetchingTemplates(false);
    };

    if (channelValue && (!subOrganizationEnabled || !isEmpty(subOrganizationsValue)) && !isEmpty(lobsValue)) {
      getTemplates();
    } else {
      setTemplates([]);
    }
  }, [
    organizationId,
    subOrganizationEnabled,
    isAllSubOrganizations,
    subOrganizationsValue,
    channelValue,
    lobsValue,
    supportedEvents,
    eventKey,
  ]);

  return {
    isFetchingTemplates,
    templates,
  };
};

export default AddOrEditRuleDialog;
export { FIELD_IDS };

import React, { useCallback, useState } from 'react';
import { InputAdornment, Link, Switch } from '@material-ui/core';
import axios from 'axios';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Tooltip from '~/components/core/Atomic/Tooltip';
import InnerCard from '~/components/core/Cards/InnerCard';
import EmptyState from '~/components/core/EmptyState';
import TextField from '~/components/core/Molecules/Fields/TextField';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import cn from '~/Utils/cn';

import { getSubCategoriesDict } from '../../../../ClosingReasonUtils';
import { serverDateToLocal } from '../../../../DateTimeUtils';
import { CLOSING_REASON_CATEGORIES } from '../../../../Types';
import { reportAxiosError } from '../../../../Utils';
import { getLobDescription } from '../../../../Utils/lobUtils';
import CardDialog from '../../../CardDialog';
import {
  LoadingSwitch,
  MultiSelectFilter,
  OverflowArrayTextDisplayWithTooltip,
  SortableTable,
  Text,
} from '../../../core';
import { CancelIcon, PencilIcon, RemoveIcon, TrashIcon } from '../../../icons';
import InlineIconButton from '../../../InlineIconButton';
import useDataFetcher from '../../../useDataFetcher';
import { useSysconfig } from '../../SystemConfigurationScreen';
import { AsyncSwitch } from '../../Tabs/DeveloperTools/sections/VendorAPIProfiles/VendorAPIProfilesTable/AsyncSwitch';

import AddEditReasonDialog from './AddEditReasonDialog';
import DeleteReasonDialog from './DeleteReasonDialog';
import Header from './Header';

import { useStyles } from '../../../../assets/styles';

const ClosingReasonConfigScreen = () => {
  const classes = useStyles();
  const { lobConfigurationsDict } = useLobConfiguration();
  const { organization, organizationOperationalDetails } = useSysconfig();
  const [searchReasonText, setSearchReasonText] = useState('');
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedSubOrgs, setSelectedSubOrgs] = useState([]);
  const [selectedLOBs, setSelectedLOBs] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [hideDisabled, setHideDisabled] = useState(false);
  const [showAddEditReasonDialog, setShowAddEditReasonDialog] = useState(false);
  const [reasonToDelete, setReasonToDelete] = useState(null);
  const [reasonToEdit, setReasonToEdit] = useState(null);

  const baseClosingReasonConfigsRoute = `/api/v1/organizations/${organization.id}/closing_reason_configs`;

  const {
    isLoading,
    isError,
    data: closingReasonConfigs,
    reloadData: reloadClosingReasonConfigs,
  } = useDataFetcher(baseClosingReasonConfigsRoute);

  const resetFilters = () => {
    setSelectedTypes([]);
    setSelectedLOBs([]);
    setSelectedSubOrgs([]);
    setSelectedCategories([]);
    setSelectedSubCategories([]);
    setHideDisabled(false);
  };

  const isClosingReasonFitFilters = useCallback(
    ({
      closing_reason_key,
      display_name,
      sub_orgs,
      is_org_level,
      is_all_lobs,
      lobs,
      category,
      sub_category,
      type,
      is_active,
    }) => {
      const foundTypes = selectedTypes.includes(type);
      const typesFilter = selectedTypes.length === 0 || foundTypes;

      const lobDescriptions = lobs.map((lob) => getLobDescription(lob, lobConfigurationsDict));
      const foundSubOrgs = selectedSubOrgs.some((selected) => sub_orgs.includes(selected));
      const subOrgFilter = is_org_level || selectedSubOrgs.length === 0 || foundSubOrgs;

      const foundLOBs = selectedLOBs.some((selected) => lobDescriptions.includes(selected));
      const lOBsFilter = is_all_lobs || selectedLOBs.length === 0 || foundLOBs;

      const foundCategories = selectedCategories.includes(category);
      const categoriesFilter = selectedCategories.length === 0 || foundCategories;

      const foundSubCategories = selectedSubCategories.includes(sub_category);
      const subcategoriesFilter = selectedSubCategories.length === 0 || foundSubCategories;

      const shouldFilterIsActive = !hideDisabled || (hideDisabled && is_active);

      return (
        shouldFilterIsActive &&
        typesFilter &&
        subcategoriesFilter &&
        categoriesFilter &&
        lOBsFilter &&
        subOrgFilter &&
        ((display_name?.toLowerCase() || '').includes(searchReasonText.toLowerCase()) ||
          (closing_reason_key?.toLowerCase() || '').includes(searchReasonText?.toLowerCase()))
      );
    },
    [
      selectedTypes,
      selectedSubOrgs,
      selectedLOBs,
      selectedCategories,
      selectedSubCategories,
      hideDisabled,
      searchReasonText,
      lobConfigurationsDict,
    ]
  );

  const filteredClosingReasonConfigs = closingReasonConfigs?.filter(isClosingReasonFitFilters);
  const Counter = () => (
    <Text
      weight={Text.WEIGHTS.SEMI_BOLD}
      variant={Text.VARIANTS.SM}
    >{`${filteredClosingReasonConfigs?.length} Closing Reasons`}</Text>
  );

  const handleSwitchChange = async (row, checked) => {
    try {
      await axios.patch(`${baseClosingReasonConfigsRoute}/${row.db_id}`, {
        is_enabled: checked,
        type: row.type,
      });
      await reloadClosingReasonConfigs();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleDelete = async (reasonToDelete) => {
    try {
      await axios.delete(`${baseClosingReasonConfigsRoute}/${reasonToDelete.db_id}/${reasonToDelete.type}`);
      await reloadClosingReasonConfigs();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const onClose = () => {
    setShowAddEditReasonDialog(false);
    setReasonToEdit(null);
  };

  const handleCreateClosingReasonConfig = async (values) => {
    await axios.post(`${baseClosingReasonConfigsRoute}`, values);
    await reloadClosingReasonConfigs();
  };

  const handleUpdateClosingReasonConfig = async (values, reasonId) => {
    await axios.put(`${baseClosingReasonConfigsRoute}/${reasonId}`, values);
    await reloadClosingReasonConfigs();
  };

  const actionsCell = ({ onDelete, onEdit }) => ({
    id: 'actions',
    label: 'Actions',
    disableSort: true,
    width: '70px',
    specialCell: (row) => (
      <div>
        <InlineIconButton
          icon={PencilIcon}
          tooltipTitle="Edit"
          className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
          onClick={() => onEdit(row)}
          wrapWithSpan
        />
        <InlineIconButton
          icon={TrashIcon}
          tooltipTitle="Delete"
          className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
          onClick={() => onDelete(row)}
          wrapWithSpan
        />
      </div>
    ),
  });

  const columns = [
    {
      id: 'display_name',
      label: 'Display Name',
    },
    {
      id: 'closing_reason_key',
      label: 'Closing Reason Key',
    },
    {
      id: 'category',
      label: 'Category',
      specialCell: ({ category }) => CLOSING_REASON_CATEGORIES[category].display_name,
    },
    {
      id: 'sub_category',
      label: 'Sub Category',
      specialCell: ({ category, sub_category }) =>
        CLOSING_REASON_CATEGORIES[category]['sub_categories'][sub_category].display_name,
    },
    {
      id: 'sub_orgs',
      label: 'Sub Organizations',
      isHidden: !organization.sub_organizations_enabled,
      specialCell: ({ is_org_level, sub_orgs }) =>
        is_org_level ? (
          'All'
        ) : (
          <div>
            <OverflowArrayTextDisplayWithTooltip
              value={sub_orgs}
              renderItem={(label) => (
                <span key={label}>
                  <Chip label={label} size="small" className="mr-12" />
                </span>
              )}
            />
          </div>
        ),
      disableSort: true,
    },
    {
      id: 'lobs',
      label: 'Lines of Business',
      specialCell: ({ is_all_lobs, lobs }) =>
        is_all_lobs ? (
          'All'
        ) : (
          <div>
            <OverflowArrayTextDisplayWithTooltip
              value={lobs.map((lob) => getLobDescription(lob, lobConfigurationsDict))}
              renderItem={(label) => (
                <span key={label}>
                  <Chip label={label} size="small" className="mr-12" />
                </span>
              )}
            />
          </div>
        ),
      disableSort: true,
    },
    {
      id: 'last_updated_datetime',
      label: 'Last Updated',
      specialCell: ({ last_updated_datetime }) => serverDateToLocal(last_updated_datetime),
    },
    {
      id: 'last_updated_by_user',
      label: 'Updated By',
    },
    {
      id: 'is_active',
      label: 'Enabled',
      specialCell: (row) => (
        <Tooltip title="Disabled reason will not be shown in the edit options dropdown when closing an exposure or claim">
          <span>
            <AsyncSwitch checked={row.is_active} onChange={(e) => handleSwitchChange(row, e.target.checked)} />
          </span>
        </Tooltip>
      ),
    },
    actionsCell({
      onEdit: (_config) => {
        setReasonToEdit(_config);
        setShowAddEditReasonDialog(true);
      },
      onDelete: (_config) => {
        setReasonToDelete(_config);
      },
    }),
  ];

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <CardDialog noCardTitle>
        <div className="mx-20 my-12">
          <Header />
          <Grid container direction="column" spacing={2}>
            <Grid item xs={3} className="my-20">
              <TextField
                className={classes.textField}
                style={{ width: '220px' }}
                value={searchReasonText}
                onChange={(value) => setSearchReasonText(value)}
                InputProps={{
                  placeholder: 'Search Reason',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton title="Clear" onClick={() => setSearchReasonText('')}>
                        <RemoveIcon className={classes.hoverableNonStrokedIcon} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InnerCard className="p-24">
                <Grid container direction="column">
                  <Grid item xs={12} className="mb-20">
                    <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD}>
                      Filters
                    </Text>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={4}>
                  <Grid item>
                    <MultiSelectFilter
                      label="By Claim/Exposure"
                      value={selectedTypes}
                      onChange={(value) => {
                        setSelectedTypes(value);
                      }}
                      options={['claim', 'exposure']}
                      renderOption={(option) => (option === 'claim' ? 'Claim' : 'Exposure')}
                      withOptionChips
                    />
                  </Grid>
                  {organization.sub_organizations_enabled && (
                    <Grid item>
                      <MultiSelectFilter
                        label="By Sub-Organization"
                        value={selectedSubOrgs}
                        onChange={(value) => {
                          setSelectedSubOrgs(value);
                        }}
                        options={organizationOperationalDetails.subOrganizations.map((subOrg) => subOrg.name)}
                        withOptionChips
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <MultiSelectFilter
                      label="By Line of Business"
                      value={selectedLOBs}
                      onChange={(value) => {
                        setSelectedLOBs(value);
                      }}
                      options={organizationOperationalDetails.orgLevelSupportedLobs.map((lob) =>
                        getLobDescription(lob, lobConfigurationsDict)
                      )}
                      withOptionChips
                    />
                  </Grid>
                  <Grid item>
                    <MultiSelectFilter
                      label="By Category"
                      value={selectedCategories}
                      onChange={(value) => {
                        setSelectedCategories(value);
                      }}
                      options={Object.keys(CLOSING_REASON_CATEGORIES)}
                      renderOption={(category) => CLOSING_REASON_CATEGORIES[category].display_name}
                      withOptionChips
                    />
                  </Grid>
                  <Grid item>
                    <MultiSelectFilter
                      label="By Sub-Category"
                      value={selectedSubCategories}
                      onChange={(value) => {
                        setSelectedSubCategories(value);
                      }}
                      options={Object.keys(getSubCategoriesDict())}
                      renderOption={(option) => getSubCategoriesDict()[option].display_name}
                      withOptionChips
                    />
                  </Grid>
                  <Grid item>
                    <Link
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        resetFilters();
                      }}
                      className={cn(classes.alignedContainerForGrid, classes.secondaryLink, 'pt-4')}
                    >
                      <CancelIcon className={cn(classes.filledIcon, classes.leftButtonIcon)} />
                      Clear all filters
                    </Link>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.alignedContainerForGrid}>
                    <Switch
                      checked={hideDisabled}
                      onChange={() => setHideDisabled(!hideDisabled)}
                      className={classes.formsSwitch}
                      size="small"
                    />
                    <span>Show only enabled closing reasons</span>
                  </div>
                </Grid>
              </InnerCard>
            </Grid>
            <Grid>
              <Grid item xs={12} className="float-right mr-12">
                <Button
                  color="primary"
                  onClick={() => {
                    setShowAddEditReasonDialog(true);
                  }}
                >
                  <AddIcon className={classes.leftButtonIcon} />
                  ADD REASON
                </Button>
              </Grid>
              <Grid item xs={12} className="m-12">
                <Counter />
              </Grid>
            </Grid>
          </Grid>

          <SortableTable
            columns={columns}
            rows={filteredClosingReasonConfigs}
            stickyHeader
            maxHeight="50vh"
            emptyStateComponent={<EmptyState title="No closing reasons configured yet" />}
          />
          {showAddEditReasonDialog && (
            <AddEditReasonDialog
              closingReasonConfigs={closingReasonConfigs}
              reasonToEdit={reasonToEdit}
              onSubmit={reasonToEdit ? handleUpdateClosingReasonConfig : handleCreateClosingReasonConfig}
              onClose={onClose}
            />
          )}
          {reasonToDelete && (
            <DeleteReasonDialog
              reasonToDelete={reasonToDelete}
              onClose={() => {
                setReasonToDelete(null);
              }}
              onSubmit={() => {
                setReasonToDelete(null);
                handleDelete(reasonToDelete);
              }}
            />
          )}
        </div>
      </CardDialog>
    </LoadingSwitch>
  );
};
export default ClosingReasonConfigScreen;

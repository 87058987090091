import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import _, { noop } from 'lodash';

import MenuItem from '~/components/core/Atomic/MenuItem';
import SingleSelectField from '~/components/core/Molecules/Fields/SingleSelectField';

import useOrganization from '../../OrganizationContext';

import { useStyles } from '../../../assets/styles';

const SubOrganizationMultiSelect = ({ onChange = noop, disabled = false }) => {
  const { subOrganizationEnabled, subOrganizations } = useOrganization();
  const classes = useStyles();
  const [selectedSubOrgIds, setSelectedSubOrgIds] = useState([]);
  const subOrgsDict = _.keyBy(subOrganizations, 'id');
  const theme = useTheme();

  if (!subOrganizationEnabled) {
    return null;
  }

  const handleSelectionChanged = (subOrgIds) => {
    setSelectedSubOrgIds(subOrgIds);
    if (onChange) {
      onChange(subOrgIds);
    }
  };

  const isSubOrgChecked = (subOrgId) => {
    return selectedSubOrgIds.includes(subOrgId);
  };

  const renderFieldValue = (subOrgIds) => {
    return subOrgIds.map((subOrgId) => subOrgsDict[subOrgId].name).join(', ');
  };

  return (
    <SingleSelectField
      value={selectedSubOrgIds}
      label="Sub Organizations"
      className={classes.textField}
      fullWidth
      disabled={disabled}
      onChange={handleSelectionChanged}
      SelectProps={{
        multiple: true,
        renderValue: renderFieldValue,
      }}
    >
      {subOrganizations.map((subOrg) => (
        <MenuItem key={subOrg.id} value={subOrg.id}>
          <Checkbox
            checked={isSubOrgChecked(subOrg.id)}
            style={{ color: isSubOrgChecked(subOrg.id) ? theme.palette.primary.darker : '#707070' }}
          />
          <ListItemText primary={subOrg.name} />
        </MenuItem>
      ))}
    </SingleSelectField>
  );
};

SubOrganizationMultiSelect.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SubOrganizationMultiSelect;

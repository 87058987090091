import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import urljoin from 'url-join';

import { useStyles } from '~/assets/styles';
import CardLink from '~/components/core/CardLink';
import GenericTemplatesConfigurationScreen from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplatesConfigurationScreen';
import StatReserveConfigurationPage from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveConfigurationPage';
import ContactRolesConfiguration from '~/components/SystemConfiguration/ContactRoles';
import FnolConfigScreen from '~/components/SystemConfiguration/FnolConfiguration';
import { OPERATIONS_MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { ADMIN_ROUTES } from '~/routesConstants';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { getIsCatSupportedForOrganization, isFeatureEnabled } from '~/Utils';
import cn from '~/Utils/cn';

import CardDialog from '../CardDialog';
import mixpanel from '../CmsMain/mixpanel';
import { isPermissionsEnabled } from '../core/Permissions/PermissionUtils';
import { useCms } from '../hooks/useCms';

import AssignmentsTab from './AutomaticRulesConfiguration/Assignments/AssignmentsTab';
import NotificationsConfigurationTool from './AutomaticRulesConfiguration/Notifications/NotificationsConfigurationTool';
import ClaimConfigurationPage, { CLAIM_CONFIG_CONFIGURATION_SUBTITLE } from './ClaimConfiguration/ClaimConfiguration';
import CommunicationConfigurations from './Communications/CommunicationConfigurations';
import ScheduledDataExportPage from './DataExport/ScheduledDataExport';
import ExternalAdminCcc from './Integrations/CCC/ExternalAdmin';
import ExternalAdminClaimSearch from './Integrations/ClaimSearch/ExternalAdmin';
import ExternalAdminNavigatorCard from './Integrations/Navigator/ExternalAdminNavigatorWrapper';
import TableauAccountsExternalAdmin from './TableauAccounts/TableauAccountsExternalAdmin';
import DeveloperToolsTab from './Tabs/DeveloperTools/DeveloperToolsTab';
import PerformanceQATab from './Tabs/PerformanceQA';
import { PermissionsProfilesPage } from './Tabs/PermissionsProfiles/PermissionsProfilesPage';
import SupportedLocations from './Tabs/SupportedLocations';
import AutomaticRulesConfiguration, { AUTOMATIC_RULES_CONFIGURATION_SUBTITLE } from './AutomaticRulesConfiguration';
import CatEventsTable from './CatastropheEvents';
import LoginConfiguration from './LoginConfiguration';
import PolicyConfiguration from './PolicyConfiguration';
import SubOrganizations from './SubOrganizations';
import { useSysconfig } from './SystemConfigurationScreen';

const OperationsTab = () => {
  const { organization, organizationOperationalDetails } = useSysconfig();
  const { user } = useCms();
  const match = useRouteMatch();
  const classes = useStyles();
  const location = useLocation();

  const shouldDisplayBreadcrumbs = () => {
    const path = location.pathname.substring(match.url.length + 1);
    const breadcrumbs = path.split('/');

    return breadcrumbs.length === 1 && !breadcrumbs[0];
  };

  const CARDS = [
    {
      title: 'Assignment Configuration',
      text: 'Enable configuration of claim assignment rules, methods and groups for automatic claim owner assignment based on defined conditions.',
      isEnabled: (organization) =>
        isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.ASSIGNMENTS_CONFIGURATION) &&
        !isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.AUTOMATIC_EXPOSURES_RULES),
      url: 'assignments_configuration',
      Component: AssignmentsTab,
    },
    {
      title: 'Performance QA Builder',
      text: 'Enable to create QA questionnaires using the QA Form Wizard.',
      isEnabled: () => user.is_org_level_supervisor,
      url: 'performance_qa',
      Component: PerformanceQATab,
    },
    {
      title: 'Sub Organizations',
      text: 'Enable classifying claims per partner and/or MGA and manage different configurations accordingly.',
      isEnabled: (organization) => organization.sub_organizations_enabled,
      url: 'sub_organizations',
      Component: SubOrganizations,
    },
    {
      title: 'Login Configuration',
      text: 'Manage login configuration and authentication methods.',
      isEnabled: () => true,
      url: 'login_configuration',
      Component: LoginConfiguration,
    },
    {
      title: 'Catastrophic Events',
      text: 'Add and manage catastrophic events for the ability to mark claims as catastrophe.',
      isEnabled: getIsCatSupportedForOrganization,
      url: 'cat_events',
      Component: CatEventsTable,
    },
    {
      title: 'Automation Rules Configuration',
      text: AUTOMATIC_RULES_CONFIGURATION_SUBTITLE,
      isEnabled: (organization) =>
        isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.AUTOMATIC_EXPOSURES_RULES),
      url: 'automation',
      Component: AutomaticRulesConfiguration,
    },
    {
      title: 'Notifications',
      text: 'Allow any permitted user to define, create and deploy notifications independently, easily and efficiently, with no code configuration tools.',
      isEnabled: (organization) =>
        !isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.AUTOMATIC_EXPOSURES_RULES),
      url: 'notifications_configuration_tool',
      Component: NotificationsConfigurationTool,
    },
    {
      title: 'Data Export',
      text: 'Create a one-time data export or schedule recurring exports.',
      isEnabled: (organization) => isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.SCHEDULED_DATA_EXPORT),
      url: 'scheduled_data_export',
      Component: ScheduledDataExportPage,
    },
    {
      title: 'Navigator',
      text: 'Enable integration with Navigator',
      isEnabled: () => organizationOperationalDetails.isNavigatorEnabled,
      url: 'navigator',
      Component: ExternalAdminNavigatorCard,
    },
    {
      title: 'ClaimSearch',
      text: 'Enable integration with ClaimSearch\n(Access cause of loss codes setup from here)',
      isEnabled: () => organizationOperationalDetails.isClaimSearchEnabled,
      url: 'claim_search',
      Component: ExternalAdminClaimSearch,
    },
    {
      title: 'Supported Locations',
      text: 'Configure the supported locations per each location category.',
      isEnabled: () => true,
      url: 'supported_locations',
      Component: () => <SupportedLocations displayBreadcrumbs displayTitle />,
    },
    {
      title: 'Communication',
      text: 'Manage communication configurations and settings. Includes general email classification.',
      isEnabled: () => true,
      url: 'communications',
      Component: CommunicationConfigurations,
      disablePaddingToComponent: true,
    },
    {
      title: PermissionsProfilesPage.title,
      text: PermissionsProfilesPage.subtitle,
      isEnabled: (organization) => isPermissionsEnabled(organization),
      url: 'permissions',
      Component: PermissionsProfilesPage,
      disablePaddingToComponent: true,
    },
    {
      title: 'Dev Tools',
      text: 'dev tools text',
      isEnabled: (organization) => isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.EXTERNAL_DEV_TOOLS),
      url: 'dev_tools',
      Component: DeveloperToolsTab,
    },
    {
      title: 'Claim Configuration',
      text: CLAIM_CONFIG_CONFIGURATION_SUBTITLE,
      isEnabled: (organization) => isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.NEW_CLOSING_REASON),
      url: 'claim_config',
      Component: ClaimConfigurationPage,
    },
    {
      title: 'FNOL Configuration',
      text: 'Customize elements on the FNOL form. You can configure a custom FNOL form for different sub organizations and lines of business.',
      isEnabled: (organization) => isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL),
      url: 'fnol_config',
      Component: FnolConfigScreen,
      disablePaddingToComponent: true,
    },
    {
      title: 'Policy Configuration',
      text: 'Customize elements on the policy form. You can configure a custom policy form for different sub organizations and lines of business.',
      isEnabled: (organization) => isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL),
      url: 'policy_configuration',
      Component: PolicyConfiguration,
      disablePaddingToComponent: true,
    },
    {
      title: 'Tableau Accounts Manager',
      text: 'Manage Tableau account access',
      isEnabled: (organization) =>
        isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.TABLEAU_ACCOUNTS) &&
        organizationOperationalDetails?.tableauAccountsConfiguration?.is_tableau_accounts_enabled,
      url: 'tableau_accounts',
      Component: TableauAccountsExternalAdmin,
    },
    {
      title: 'CCC',
      text: 'Manage CCC integration',
      isEnabled: () => organizationOperationalDetails?.isCccEnabled,
      url: 'ccc',
      Component: ExternalAdminCcc,
    },
    {
      title: 'Templates',
      text: 'Create, edit and preview the templates for Emails, Notes and SMS.',
      isEnabled: () => true,
      url: 'generic_templates_configuration',
      Component: GenericTemplatesConfigurationScreen,
    },
    {
      title: 'Contact Roles',
      text: 'View and manage contact roles in the system. Includes setting up and defining custom contact roles.',
      isEnabled: (organization) =>
        isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.CONTACT_ROLES_CONFIGURATION_SCREEN),
      url: 'contact_roles',
      Component: ContactRolesConfiguration,
    },
    {
      title: 'Reserve Configuration',
      text: 'Configure custom rules to automatically set initial reserves when opening a new exposure.',
      isEnabled: (organization) => isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.STAT_RESERVES),
      url: ADMIN_ROUTES.STAT_RESERVES,
      Component: StatReserveConfigurationPage,
    },
  ];

  const path = location.pathname.substring(match.url.length + 1);
  const breadcrumbs = path.split('/');
  const currCardIndex = CARDS.findIndex((card) => breadcrumbs.includes(card.url));
  const cardTitle = currCardIndex >= 0 ? CARDS[currCardIndex].title : '';

  useEffect(() => {
    if (currCardIndex < 0) {
      mixpanel.track(OPERATIONS_MIXPANEL_EVENTS.OPERATIONS_SCREEN_VIEWED);
    } else {
      mixpanel.track(OPERATIONS_MIXPANEL_EVENTS.OPERATIONS_COMPONENT_CLICKED, {
        component: cardTitle,
      });
    }
  }, [cardTitle, currCardIndex]);

  const filteredCard = CARDS.filter(({ isEnabled }) => isEnabled(organization));
  filteredCard.sort((a, b) => (a.title > b.title ? 1 : -1));

  return (
    <CardDialog noCardTitle contentStyle={{ padding: '0px' }}>
      {shouldDisplayBreadcrumbs() && (
        <div className="ml-16 mt-24 flex flex-wrap pl-32 pt-16">
          {filteredCard.map(({ text, title, url }) => (
            <CardLink key={title} title={title} text={text} to={`${match.url}/${url}`} />
          ))}
        </div>
      )}
      <div className={classes.pageBody}>
        <Switch>
          <Redirect exact from={`${match.path}/`} to={urljoin(match.path, '')} />
          <>
            {CARDS.map(({ url, Component, disablePaddingToComponent = false }) => (
              <Route
                key={url}
                path={urljoin(match.path, url)}
                render={(routerProps) => (
                  <div className={cn({ 'pb-24 pl-16 pr-24 pt-4': !disablePaddingToComponent })}>
                    <Component classes={classes} {...routerProps} />
                  </div>
                )}
              />
            ))}
          </>
          <Redirect to={match.path} />
        </Switch>
      </div>
    </CardDialog>
  );
};

export default OperationsTab;

import React, { useCallback, useEffect } from 'react';
import { getIn, useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';

import MenuItem from '~/components/core/Atomic/MenuItem';
import Heading from '~/components/core/TextComponents/Heading';
import type { ConditionOption } from '~/components/SystemConfiguration/AutomaticRulesConfiguration/Conditions/types';
import cn from '~/Utils/cn';

import { TrashIcon } from '../../../icons';
import InlineIconButton from '../../../InlineIconButton';
import { TextFieldFormik, useSetDefaultFieldsOnChange } from '../../../TextFieldFormik';

import ConditionValueField, { MULTI_SELECT_OPERATORS } from './ConditionValueField/ConditionValueField';
import { CONDITION_FIELD_IDS } from './Conditions';

import { useStyles } from '../../../../assets/styles';

interface ConditionProps {
  conditionOptions: ConditionOption[];
  disabled: boolean;
  conditionFieldId: string;
  title: string;
  onRemoveCondition: () => void;
}

const Condition: React.FC<ConditionProps> = ({
  title,
  conditionFieldId,
  conditionOptions,
  disabled,
  onRemoveCondition,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();

  const conditionKey = getIn(values, `${conditionFieldId}.${CONDITION_FIELD_IDS.CONDITION_KEY}`);
  const conditionValue1 = getIn(values, `${conditionFieldId}.${CONDITION_FIELD_IDS.VALUE_1}`);
  const conditionValue2 = getIn(values, `${conditionFieldId}.${CONDITION_FIELD_IDS.VALUE_2}`);
  const conditionOperator1 = getIn(values, `${conditionFieldId}.${CONDITION_FIELD_IDS.OPERATOR_1}`);
  const conditionOperator2 = getIn(values, `${conditionFieldId}.${CONDITION_FIELD_IDS.OPERATOR_2}`);

  const getSelectedConditionOption = (): ConditionOption | null | undefined => {
    return !isEmpty(conditionOptions) && !isEmpty(conditionKey)
      ? conditionOptions.find((o) => o.key === conditionKey)
      : null;
  };

  const getSelectedValue1Option = () => {
    return !isEmpty(selectedConditionOption) && !isEmpty(conditionValue1)
      ? selectedConditionOption?.operands.find((o) => o.value === conditionValue1)
      : null;
  };

  const isOperatorMultiselect = useCallback((operatorKey) => {
    return MULTI_SELECT_OPERATORS.includes(operatorKey);
  }, []);

  const selectedConditionOption = getSelectedConditionOption();
  const selectedValue1Option = getSelectedValue1Option();

  const getInitialValue1 = useCallback(() => {
    return selectedConditionOption?.operators.operator_1[0]?.is_multi_value ? [] : '';
  }, [selectedConditionOption]);

  const getInitialValue2 = useCallback(() => {
    return selectedConditionOption?.operators.operator_2?.[0]?.is_multi_value ? [] : '';
  }, [selectedConditionOption]);

  const getInitialOperator1 = useCallback(() => {
    return selectedConditionOption?.operators.operator_1.length === 1
      ? selectedConditionOption.operators.operator_1[0].key
      : '';
  }, [selectedConditionOption]);

  const getInitialOperator2 = useCallback(() => {
    return selectedConditionOption?.operators.operator_2?.length === 1
      ? selectedConditionOption.operators.operator_2[0].key
      : '';
  }, [selectedConditionOption]);

  useEffect(() => {
    if (selectedConditionOption?.operators.operator_1.length === 1) {
      setFieldValue(`${conditionFieldId}.${CONDITION_FIELD_IDS.OPERATOR_1}`, getInitialOperator1());
    }

    if (selectedConditionOption?.operators.operator_2?.length === 1) {
      setFieldValue(`${conditionFieldId}.${CONDITION_FIELD_IDS.OPERATOR_2}`, getInitialOperator2());
    }
  }, [conditionFieldId, setFieldValue, getInitialOperator1, getInitialOperator2, selectedConditionOption]);

  useSetDefaultFieldsOnChange(conditionOperator1, {
    [`${conditionFieldId}.${CONDITION_FIELD_IDS.OPERATOR_2}`]: getInitialOperator2(),
    [`${conditionFieldId}.${CONDITION_FIELD_IDS.VALUE_1}`]: getInitialValue1(),
  });

  useSetDefaultFieldsOnChange(conditionValue1, {
    [`${conditionFieldId}.${CONDITION_FIELD_IDS.VALUE_2}`]: getInitialValue2(),
  });

  useEffect(() => {
    if (
      (isOperatorMultiselect(conditionOperator1) && !Array.isArray(conditionValue1)) ||
      (!isOperatorMultiselect(conditionOperator1) && Array.isArray(conditionValue1))
    ) {
      setFieldValue(`${conditionFieldId}.${CONDITION_FIELD_IDS.VALUE_1}`, getInitialValue1());
    }
  }, [conditionOperator1, getInitialValue1, conditionValue1, setFieldValue, isOperatorMultiselect, conditionFieldId]);

  useEffect(() => {
    if (
      (isOperatorMultiselect(conditionOperator2) && !Array.isArray(conditionValue2)) ||
      (!isOperatorMultiselect(conditionOperator2) && Array.isArray(conditionValue2))
    ) {
      setFieldValue(`${conditionFieldId}.${CONDITION_FIELD_IDS.VALUE_2}`, getInitialValue2());
    }
  }, [conditionOperator2, getInitialValue2, conditionValue2, setFieldValue, isOperatorMultiselect, conditionFieldId]);

  const value1FieldBaseValues = {
    id: `${conditionFieldId}.${CONDITION_FIELD_IDS.VALUE_1}`,
    label: selectedConditionOption?.value_1_desc || '',
    disabled: !selectedConditionOption || disabled,
  };

  const value2FieldBaseValues = {
    id: `${conditionFieldId}.${CONDITION_FIELD_IDS.VALUE_2}`,
    label: selectedConditionOption?.value_2_desc || '',
    disabled: !selectedValue1Option || disabled,
  };

  return (
    <div className="grid grid-cols-12 gap-4 rounded bg-white p-12">
      <div className="col-span-11">
        <Heading variant={Heading.TYPES.H4} className="mb-8">
          {title}
        </Heading>
      </div>
      <div className="col-span-1">
        <InlineIconButton
          icon={TrashIcon}
          iconColor="currentColor"
          className={cn('hover:cursor-pointer hover:text-teal-700')}
          onClick={onRemoveCondition}
          disabled={disabled}
          defaultColor={undefined}
          iconStyle={undefined}
          tooltipTitle={undefined}
          useIconButton={undefined}
          wrapWithSpan={undefined}
          ignorePermissions={undefined}
          ariaLabel={undefined}
        />
      </div>
      <div className="col-span-6">
        <TextFieldFormik
          id={`${conditionFieldId}.${CONDITION_FIELD_IDS.CONDITION_KEY}`}
          label="Object"
          className={classes.formTextField}
          fullWidth
          select
          disabled={disabled}
        >
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {conditionOptions.map((conditionOption) => (
            <MenuItem key={conditionOption.key} value={conditionOption.key}>
              {conditionOption.desc}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </div>
      <div className="col-span-6" />
      <div className="col-span-6">
        {selectedConditionOption ? (
          <ConditionValueField
            conditionOperator={conditionOperator1}
            baseFieldProps={value1FieldBaseValues}
            operands={selectedConditionOption.operands}
          />
        ) : null}
      </div>
      {selectedConditionOption && selectedConditionOption.is_multi_value && (
        <div className="col-span-6">
          {selectedValue1Option?.sub_operands ? (
            <ConditionValueField
              conditionOperator={conditionOperator2}
              baseFieldProps={value2FieldBaseValues}
              operands={selectedValue1Option.sub_operands}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Condition;

import React from 'react';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import { getIn, useFormikContext } from 'formik';

import MenuItem from '~/components/core/Atomic/MenuItem';
import Text from '~/components/core/TextComponents/Text';
import type {
  ConditionOperator,
  Operand,
} from '~/components/SystemConfiguration/AutomaticRulesConfiguration/Conditions/types';
import cn from '~/Utils/cn';

import TextFieldFormik, { MonetaryValueTextFieldFormik, MultiSelectTextFieldFormik } from '../../../../TextFieldFormik';

// if more information about operator will be needed, move it to BE
export const MULTI_SELECT_OPERATORS = ['in'];

interface ConditionValueFieldProps {
  conditionOperator: ConditionOperator;
  baseFieldProps: { id: string; [key: string]: unknown };
  operands: Operand[];
}

interface Styles {
  formTextField: string;
}

const ConditionValueField: React.FC<ConditionValueFieldProps> = ({ conditionOperator, baseFieldProps, operands }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const classes: Styles = useStyles();
  const { values } = useFormikContext();
  const conditionValue = getIn(values, baseFieldProps.id);

  if (conditionOperator === 'above') {
    return (
      <div className="flex flex-row items-center">
        <Text
          colorVariant={Text.COLOR_VARIANTS.PRIMARY}
          weight={Text.WEIGHTS.REGULAR}
          variant={Text.VARIANTS.SM}
          className="mr-20 flex-shrink-0 flex-grow "
        >
          Are Above
        </Text>
        <MonetaryValueTextFieldFormik {...baseFieldProps} className={cn(classes.formTextField, 'w-full flex-shrink')} />
      </div>
    );
  }

  return MULTI_SELECT_OPERATORS.includes(conditionOperator) ? (
    Array.isArray(conditionValue) ? (
      <MultiSelectTextFieldFormik
        {...baseFieldProps}
        className={classes.formTextField}
        fullWidth
        renderOption={(option: string) => operands.find((subOperand) => subOperand.value === option)?.desc}
        options={operands.map((operand) => operand.value)}
        renderValue={() => ''}
        withOptionChips
      />
    ) : null
  ) : (
    <TextFieldFormik {...baseFieldProps} className={classes.formTextField} fullWidth select>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*@ts-ignore*/}
      {operands.map((operand) => (
        <MenuItem key={operand.value} value={operand.value}>
          {operand.desc}
        </MenuItem>
      ))}
    </TextFieldFormik>
  );
};

export default ConditionValueField;

import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import TextFieldFormik from '~/components/TextFieldFormik';

export interface VitesseConfiguration {
  client_id: string;
  client_secret: string;
}

interface VitesseSetupDialogProps {
  onSubmit: (configuration: VitesseConfiguration) => Promise<void> | void;
  onClose: () => void;
  configuration?: VitesseConfiguration;
}

const VitesseSetupDialog: React.FC<VitesseSetupDialogProps> = ({ onSubmit, onClose, configuration }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        client_id: configuration?.client_id || '',
        client_secret: configuration?.client_secret || '',
      }}
      validationSchema={Yup.object().shape({
        client_id: Yup.string().required('Required'),
        client_secret: Yup.string().required('Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <CardDialog
          title="Vitesse Integration Details"
          isDialog
          preventClose={isSubmitting}
          maxWidth="sm"
          fullWidth
          onClose={onClose}
        >
          <div className="grid gap-20">
            <TextFieldFormik id="client_id" label="Client ID" className={classes.textField} fullWidth />
          </div>
          <div className="grid gap-20">
            <TextFieldFormik id="client_secret" label="Client Secret" className={classes.textField} fullWidth />
          </div>
          <div className="mt-px flex w-full justify-end">
            <CancelButton disabled={isSubmitting} onClick={onClose} />
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={() => handleSubmit()}>
              Save
            </Button>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
};

export default VitesseSetupDialog;

import React from 'react';

import MenuItem from '~/components/core/Atomic/MenuItem';
import SingleSelectField from '~/components/core/Molecules/Fields/SingleSelectField';
import copies from '~/components/SystemConfiguration/FnolConfiguration/FnolConfigurationScreen/copies.json';

import type { SystemField } from './types';

interface SystemFieldTypeProps {
  field: SystemField;
}

const SystemFieldType: React.FC<SystemFieldTypeProps> = ({ field }: SystemFieldTypeProps) => {
  const options = copies.field_types;
  return (
    <SingleSelectField label="Type" value={field.type || ''} fullWidth disabled>
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.desc}
        </MenuItem>
      ))}
    </SingleSelectField>
  );
};

export default SystemFieldType;

import React from 'react';
import axios from 'axios';

import Chip from '~/components/core/Atomic/Chip/Chip';
import Switch from '~/components/core/Atomic/Switch';
import FsTooltip from '~/components/core/FsWrappers/FsTooltip/FsTooltip';
import OverflowArrayTextDisplayWithTooltip from '~/components/core/OverflowArrayTextWithTooltip/OverflowArrayTextDisplayWithTooltip';
import SortableTable from '~/components/core/Tables/SortableTable/index';
import type { PaginationProps, TemplateMetadata } from '~/components/GenericTemplates/types';
import { GENERIC_TEMPLATES_ROUTES } from '~/components/GenericTemplates/utils/routes';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import { serverDateTimeToLocal } from '~/DateTimeUtils';
import GENERIC_TEMPLATES_TYPES from '~/server_shared/generated-types/GENERIC_TEMPLATES_TYPES';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, isOrganizationUsOrg, reportAxiosError } from '~/Utils';
import { getLobDescription } from '~/Utils/lobUtils';

interface GenericTemplatesTablePropsType {
  templates: TemplateMetadata[];
  templatesCount: number;
  onSelectTemplate: (template_id: number) => void;
  onUpdatePaginationProps: (paginationProps: PaginationProps) => void;
  paginationProps: PaginationProps;
  shouldDisplayType: boolean;
  selectedTemplateId: number;
  reloadTable: () => Promise<void>;
}

const GenericTemplatesTable: React.FC<GenericTemplatesTablePropsType> = ({
  templates,
  templatesCount,
  onSelectTemplate,
  onUpdatePaginationProps,
  paginationProps,
  shouldDisplayType,
  selectedTemplateId,
  reloadTable,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const { lobConfigurationsDict } = useLobConfiguration();

  const handleEnableTemplate = async (templateId: number, newStatus: boolean) => {
    try {
      await axios.patch(GENERIC_TEMPLATES_ROUTES.UPDATE_TEMPLATE(organization.id, templateId), {
        is_enabled: newStatus,
      });
      await reloadTable();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const columnData = [
    { id: 'template_name', label: 'Name' },
    {
      id: 'sub_organization_ids',
      disableSort: true,
      label: 'Sub-Organizations',
      isHidden: !organization.sub_organizations_enabled,
      specialCell: (template: TemplateMetadata) => (
        <>
          {template.sub_organization_ids && template.sub_organization_ids.length > 0
            ? organization.sub_organizations
                .filter((subOrg: { id: number }) => template.sub_organization_ids.includes(subOrg.id))
                .map((subOrg: { name: string }) => subOrg.name)
                .join(', ')
            : 'All'}
        </>
      ),
    },
    {
      id: 'last_update_datetime',
      label: 'Last Updated',
      specialCell: (template: TemplateMetadata) => serverDateTimeToLocal(template.last_update_datetime),
    },
    {
      id: 'states',
      disableSort: true,
      label: 'States',
      isHidden: !isOrganizationUsOrg(organization),
      specialCell: (template: TemplateMetadata) => (
        <>{template.states && template.states.length > 0 ? template.states.join(', ') : 'All'}</>
      ),
    },
    {
      id: 'lobs',
      disableSort: true,
      label: 'Line of Business',
      specialCell: (template: TemplateMetadata) => (
        <>
          {template.lobs && template.lobs.length > 0
            ? template.lobs.map((lob) => (
                <Chip label={getLobDescription(lob, lobConfigurationsDict)} size="small" key={lob} className="mx-2" />
              ))
            : 'All'}
        </>
      ),
    },
    {
      id: 'connected_automatic_rule_actions_display_names',
      disableSort: true,
      label: 'Connected Automation Rules',
      isHidden: !isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.AUTOMATIC_COMMUNICATIONS_RULES),
      specialCell: (template: TemplateMetadata) => (
        <OverflowArrayTextDisplayWithTooltip value={template.connected_automatic_rule_actions_display_names} />
      ),
    },
    {
      id: 'template_type',
      disableSort: true,
      label: 'Type',
      specialCell: (template: TemplateMetadata) => GENERIC_TEMPLATES_TYPES[template.template_type]?.display_name,
      isHidden: !shouldDisplayType,
    },
    {
      id: 'is_enabled',
      disableSort: true,
      label: 'Enable',
      specialCell: (template: TemplateMetadata) => {
        const disabled = !!(
          template.connected_automatic_rule_actions_display_names &&
          template.connected_automatic_rule_actions_display_names.length > 0
        );
        return (
          <FsTooltip title={disabled ? 'Template cannot be disabled when connected to automatic rule' : ''}>
            <Switch
              checked={template.is_enabled}
              disabled={disabled}
              onChange={async (_, newStatus) => {
                await handleEnableTemplate(template.id, newStatus);
              }}
            />
          </FsTooltip>
        );
      },
    },
  ];

  const handleRowClick = (template_id: number) => {
    onSelectTemplate(template_id);
  };

  return (
    <SortableTable
      rows={templates}
      columns={columnData.filter((column) => !column.isHidden)}
      defaultOrderColumn={columnData.findIndex((column) => column.id === 'template_name')}
      order={paginationProps.order_by}
      onRowClick={handleRowClick}
      selectedRowsIds={[selectedTemplateId]}
      stickyHeader
      paginationProps={{
        page: paginationProps.page,
        rowsPerPage: paginationProps.limit,
        onChangePage: (_, newPage) => {
          const newPaginationProps = {
            ...paginationProps,
            page: newPage,
          };

          onUpdatePaginationProps(newPaginationProps);
        },
        count: templatesCount,
        onChangeRowsPerPage: (event) => {
          const newPaginationProps = {
            ...paginationProps,
            page: 0,
            limit: event.target.value,
          };

          onUpdatePaginationProps(newPaginationProps);
        },
        rowsPerPageOptions: [10, 20, 30],
      }}
      onSortByColumn={(_: () => void, { id, order }: { id: string; order: string }) => {
        const newPaginationProps = {
          ...paginationProps,
          page: 0,
          sort_by: id,
          order_by: order,
        };

        onUpdatePaginationProps(newPaginationProps);
      }}
    />
  );
};

export default GenericTemplatesTable;

import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

import { useStyles } from '~/assets/styles';
import Grid from '~/components/core/Atomic/Grid/Grid';
import InnerCard from '~/components/core/Cards/InnerCard';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import useFormikChangeListener from '~/components/core/Formik/FormikChangeListener';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import TextField from '~/components/core/Molecules/Fields/TextField';
import TextFieldFormik from '~/components/TextFieldFormik';

import copies from '../../copies.json';
import FieldConfigurationCard from '../../FieldConfigurationCard/FieldConfigurationCard';
import HiddenIndication from '../../HiddenIndication/HiddenIndication';

interface SubSectionPropsType {
  id?: string;
  idPrefix?: string;
  title: string;
  sectionKey?: string;
  lossSubSectionQuestionId?: string;
  lossObjectFieldIds?: string[];
  lossItemsFieldIds?: string[];
  lossItemsFieldIdPrefix?: string;
  lossItemsSectionDesc?: string;
  withIcdCodes?: boolean;
  children?: React.ReactNode;
}

const SubSectionWrapper: React.FC<SubSectionPropsType> = ({
  id,
  idPrefix,
  title,
  sectionKey,
  lossSubSectionQuestionId,
  lossObjectFieldIds,
  lossItemsFieldIds,
  lossItemsFieldIdPrefix,
  lossItemsSectionDesc,
  withIcdCodes = false,
  children,
}) => {
  const classes = useStyles();
  const [isHidden, setIsHidden] = useState(false);
  const [isItemsListHidden, setIsItemsListHidden] = useState(false);
  const { setFieldValue } = useFormikContext();

  // Enforce all supplementary section to be hidden if the section is show is set to false
  useFormikChangeListener({
    listenForKeys: [`${id}.active`, `${id}.${lossItemsFieldIdPrefix}.active`],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange: (values, _) => {
      !get(values, `${id}.active`, false) ? setIsHidden(true) : setIsHidden(false);
      !get(values, `${id}.active`, false)
        ? setFieldValue(`${idPrefix}.${lossSubSectionQuestionId}.active`, false)
        : setFieldValue(`${idPrefix}.${lossSubSectionQuestionId}.active`, true);
      !get(values, `${id}.${lossItemsFieldIdPrefix}.active`, false)
        ? setIsItemsListHidden(true)
        : setIsItemsListHidden(false);
    },
    runOnFirstRender: true,
  });

  return (
    <Grid item xs={12}>
      <CollapsibleWrapper
        title={title}
        subtitle={sectionKey ? `Key: ${sectionKey}` : undefined}
        backgroundColor={CollapsibleWrapper.BACKGROUND_COLOR.LIST}
        actionCard
        noBorderLine
        noDivider
        actions={<HiddenIndication isActive={!isHidden} />}
        withActionsContainerFullWidth={false}
        defaultState={false}
      >
        <InnerCard>
          <Grid container className="flex items-center" spacing={3}>
            {lossSubSectionQuestionId && (
              <>
                <Grid item xs={6}>
                  <TextFieldFormik id={`${id}.desc`} value={title} label="Display Name" fullWidth disabled />
                </Grid>
                <Grid item xs={6}>
                  <TextField id={id} value={sectionKey} label="Key" fullWidth disabled />
                </Grid>
                <Grid item xs={2}>
                  <SwitchFormik id={`${id}.active`} label="Show" className={classes.formsSwitch} hideErrorGap />
                </Grid>
                <Grid item xs={10} />
                <FieldConfigurationCard
                  idPrefix={idPrefix}
                  fieldId={lossSubSectionQuestionId}
                  backgroundColor={FieldConfigurationCard.BACKGROUND_COLOR.WHITE}
                  forceRequired
                  disableCategories
                  disabledCollapse={isHidden}
                />
                {lossObjectFieldIds?.map((fieldId) => (
                  <FieldConfigurationCard
                    key={fieldId}
                    idPrefix={id}
                    fieldId={fieldId}
                    backgroundColor={FieldConfigurationCard.BACKGROUND_COLOR.WHITE}
                    disableCategories
                    disabledCollapse={isHidden}
                  />
                ))}
                {lossItemsFieldIdPrefix && (
                  <Grid item xs={12}>
                    <CollapsibleWrapper
                      title={lossItemsSectionDesc || ''}
                      subtitle={`Key: ${lossItemsFieldIdPrefix}`}
                      backgroundColor={CollapsibleWrapper.BACKGROUND_COLOR.WHITE}
                      actionCard
                      noBorderLine
                      noDivider
                      actions={<HiddenIndication isActive={!isItemsListHidden && !isHidden} />}
                      withActionsContainerFullWidth={false}
                      defaultState={true}
                      disabled={isHidden}
                    >
                      <InnerCard>
                        <Grid container className="flex items-center" spacing={3}>
                          <Grid item xs={2}>
                            <SwitchFormik
                              id={`${id}.${lossItemsFieldIdPrefix}.active`}
                              label="Show"
                              className={classes.formsSwitch}
                              hideErrorGap
                            />
                          </Grid>
                          <Grid item xs={10} />
                          {lossItemsFieldIds?.map((fieldId) => (
                            <FieldConfigurationCard
                              key={fieldId}
                              idPrefix={`${id}.${lossItemsFieldIdPrefix}`}
                              fieldId={fieldId}
                              backgroundColor={FieldConfigurationCard.BACKGROUND_COLOR.WHITE}
                              disableCategories
                              disabledCollapse={isHidden || isItemsListHidden}
                            />
                          ))}
                        </Grid>
                      </InnerCard>
                    </CollapsibleWrapper>
                  </Grid>
                )}
                {withIcdCodes && (
                  <FieldConfigurationCard
                    idPrefix={id}
                    fieldId="icd_codes"
                    disabledCollapse={isHidden}
                    backgroundColor={FieldConfigurationCard.BACKGROUND_COLOR.WHITE}
                    disableCategories
                    infoTooltipText={copies.icd_codes_tooltip}
                  />
                )}
              </>
            )}
            {children}
          </Grid>
        </InnerCard>
      </CollapsibleWrapper>
    </Grid>
  );
};

export default SubSectionWrapper;

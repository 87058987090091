import React from 'react';

import InnerTabs from '../../core/Layout/InnerTabs/InnerTabs';
import { useCms } from '../../hooks/useCms';
import useOrganization from '../../OrganizationContext';
import EmailConfiguration from '../EmailConfiguration/EmailConfiguration';
import GeneralEmailConfig from '../GeneralQueue/GeneralEmailConfig';
import OperationsBreadcrumbs from '../OperationsBreadcrumbs';
import OrganizationCommunicationsTab from '../OrganizationCommunicationsTab';
import { useSysconfig } from '../SystemConfigurationScreen';

import DyteVideoConfigurationTab from './DyteVideoConfigurationTab/DyteVideoConfigurationTab';
import PhoneConfigurationTab from './PhoneConfigurationTab/PhoneConfigurationTab';
import QueueManagementTab from './QueueManagementTab/QueueManagementTab';

const CommunicationConfigurations = () => {
  const { userOrganization } = useCms();
  const { isTwilioEnabled, isDyteVideoInitialized, isDyteVideoEnabled } = useOrganization();
  const { organizationOperationalDetails } = useSysconfig();

  const showTwilioConfigTabs = isTwilioEnabled;
  const tabs = [
    {
      label: 'Users Configuration',
      url: 'users_communications',
      component: OrganizationCommunicationsTab,
      disableTab: !userOrganization.is_super_organization,
    },
    {
      label: 'Phone Configuration',
      url: 'phone_configuration',
      component: PhoneConfigurationTab,
      disableTab: !showTwilioConfigTabs,
    },
    {
      label: 'Queue Management',
      url: 'queue_management',
      component: QueueManagementTab,
      disableTab: !showTwilioConfigTabs || !organizationOperationalDetails.twilioConfiguration.is_queues_enabled,
    },
    {
      label: 'General Email Config',
      url: 'general_email_classification',
      component: GeneralEmailConfig,
      disableTab: false,
    },
    {
      label: 'Video Calls Config',
      url: 'dyte_video_config',
      component: DyteVideoConfigurationTab,
      disableTab: userOrganization.is_super_organization ? !isDyteVideoInitialized : !isDyteVideoEnabled,
    },
    {
      label: 'Email Configuration',
      url: 'email_configuration',
      component: EmailConfiguration,
      disableTab: false,
    },
  ];

  return (
    <>
      <OperationsBreadcrumbs currentTab="Communications" addPadding />
      <InnerTabs tabs={tabs} />
    </>
  );
};

export default CommunicationConfigurations;

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';
import axios from 'axios';

import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Tooltip from '~/components/core/Atomic/Tooltip';
import EmptyState from '~/components/core/EmptyState';
import TextField from '~/components/core/Molecules/Fields/TextField';

import { serverDateToLocalMoment } from '../../../DateTimeUtils';
import { reportAxiosError, stringCmp } from '../../../Utils';
import CardDialog from '../../CardDialog';
import WithConfirm from '../../ConfirmModal';
import { FsButton, LoadingSwitch, OverflowArrayTextDisplayWithTooltip, SortableTable, Text } from '../../core';
import { AddIcon, PencilIcon, RemoveIcon, TrashIcon } from '../../icons';
import InlineIconButton from '../../InlineIconButton';
import useDataFetcher from '../../useDataFetcher';
import { useSysconfig } from '../SystemConfigurationScreen';
import { AsyncSwitch } from '../Tabs/DeveloperTools/sections/VendorAPIProfiles/VendorAPIProfilesTable/AsyncSwitch';

import CoverageGroupDialog from './CoverageGroupDialog';

import { useStyles } from '../../../assets/styles';
import colors from '../../../assets/colors.module.scss';
import styles from './coveragesGroups.module.scss';

const COVERAGES_GROUPS_SUBTITLE =
  "Manage coverage groups for your organization. You can add a new coverage group, edit groups and view a group's coverages.";

const CoveragesGroups = () => {
  const { organization } = useSysconfig();
  const [showDialog, setShowDialog] = useState(false);
  const [groupToEdit, setGroupToEdit] = useState(null);
  const [searchText, setSearchText] = useState('');

  const classes = useStyles();

  const isCoverageFitFilters = useCallback(
    (coverageGroup) => {
      const doesMatchText =
        (coverageGroup.display_name?.toLowerCase() || '').includes(searchText.toLowerCase()) ||
        (coverageGroup.coverage_group_key?.toLowerCase() || '').includes(searchText?.toLowerCase());

      return doesMatchText;
    },
    [searchText]
  );

  const baseRoute = `/sysconfig/api/v1/organizations/${organization.id}/coverages/groups`;
  const { isLoading, isError, data, reloadData } = useDataFetcher(baseRoute);

  const filteredCoverageGroups = data?.filter(isCoverageFitFilters);

  const fetchUsedCoverageGroupKeys = ({ ids_to_ignore }) =>
    axios.get(`${baseRoute}/keys_used`, { params: { ids_to_ignore } });

  const callAPIWithCatch = async (method, url, values) => {
    try {
      await axios({ method, url, data: values });
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const onCreateGroup = async (values) => {
    await callAPIWithCatch('post', baseRoute, values);
    await reloadData();
  };

  const onUpdateGroup = async (values, groupId) => {
    await callAPIWithCatch('put', `${baseRoute}/${groupId}`, values);
    await reloadData();
  };

  const onToggleDisableGroup = async (groupId, shouldDisable) => {
    await callAPIWithCatch('put', `${baseRoute}/${groupId}`, { is_disabled: shouldDisable });
    await reloadData();
  };

  const onDeleteGroup = async (groupId) => {
    await callAPIWithCatch('delete', `${baseRoute}/${groupId}`);
    await reloadData();
  };

  const onClose = () => {
    setGroupToEdit(null);
    setShowDialog(false);
  };

  const Counter = () => (
    <Text
      weight={Text.WEIGHTS.SEMI_BOLD}
      variant={Text.VARIANTS.SM}
    >{`${filteredCoverageGroups?.length} Coverage Groups`}</Text>
  );

  const columnsData = [
    {
      id: 'display_name',
      numeric: false,
      label: 'Group Name',
    },
    {
      id: 'coverage_group_key',
      numeric: false,
      label: 'Coverage Group Key',
    },
    {
      id: 'coverages_configs',
      numeric: false,
      label: 'Coverages',
      specialCell: (row) => (
        <div className={styles.coveragesChipsContainer}>
          <OverflowArrayTextDisplayWithTooltip
            value={row.coverages_configs}
            additionalLabelWidth={24}
            renderItem={(label) => (
              <span className={styles.coverageChip}>
                <Chip label={label} size="small" />
              </span>
            )}
          />
        </div>
      ),
      disableSort: true,
    },
    {
      id: 'last_updated_datetime',
      numeric: false,
      label: 'Last Update',
      specialCell: (row) => serverDateToLocalMoment(row.last_updated_datetime, false),
      specialCmpFunc: (row1, row2) => stringCmp(row1.last_updated_datetime, row2.last_updated_datetime),
    },
    {
      id: 'last_updated_by_user',
      numeric: false,
      label: 'Updated By',
    },
    {
      id: 'is_enabled',
      numeric: false,
      label: 'Enable',
      specialCell: (row) => (
        <div className={styles.enableSwitchContainer}>
          <Tooltip
            arrow
            placement="top"
            title="Disabled group will not be shown in the options dropdowns when creating or editing a coverage"
          >
            <span>
              <AsyncSwitch
                checked={!row.is_disabled}
                onChange={(e) => onToggleDisableGroup(row.id, !e.target.checked)}
              />
            </span>
          </Tooltip>
        </div>
      ),
      specialCmpFunc: (row1, row2) => stringCmp(row1.is_disabled, row2.is_disabled),
    },
    {
      id: 'actions',
      disableSort: true,
      label: 'Actions',
      width: '70px',
      specialCell: (group) => (
        <div className={styles.actionsWrapper}>
          <InlineIconButton
            icon={PencilIcon}
            className={classes.hoverableNonFilledIcon}
            onClick={() => {
              setGroupToEdit(group);
              setShowDialog(true);
            }}
          />
          <WithConfirm
            title="Delete Group?"
            contentText={`Are you sure you want to delete '${group.display_name}'`}
            primaryButtonName="Delete"
            postOnClick={async () => await reloadData()}
          >
            <InlineIconButton
              icon={TrashIcon}
              className={group.coverages_configs?.length ? '' : classes.hoverableNonFilledIcon}
              onClick={async () => await onDeleteGroup(group.id)}
              disabled={!!group.coverages_configs?.length}
              tooltipTitle="a used coverage group can not be deleted"
            />
          </WithConfirm>
        </div>
      ),
    },
  ];

  return (
    <LoadingSwitch isLoading={isLoading} isError={isError}>
      <CardDialog noCardTitle>
        <div className={styles.coveragesGroupsWrapper}>
          <Title />
          {data?.length > 0 && (
            <Grid container direction="column" spacing={2}>
              <Grid item xs={3} className={styles.searchFieldWrapper}>
                <TextField
                  value={searchText}
                  fullWidth
                  onChange={(value) => setSearchText(value)}
                  InputProps={{
                    placeholder: 'Search Coverage Group',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton title="Clear" onClick={() => setSearchText('')}>
                          <RemoveIcon className={classes.hoverableNonStrokedIcon} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid>
                <Grid item xs={12} className={styles.addCoverageButtonFloating}>
                  <AddCoverageGroupButton text="ADD COVERAGE GROUP" onClick={() => setShowDialog(true)} />
                </Grid>
                <Grid item xs={12} className={styles.counterWrapper}>
                  <Counter />
                </Grid>
              </Grid>
            </Grid>
          )}
          <SortableTable
            columns={columnsData}
            rows={filteredCoverageGroups}
            stickyHeader
            maxHeight="50vh"
            emptyStateComponent={<EmptyStateComponent text="ADD COVERAGE GROUP" onClick={() => setShowDialog(true)} />}
          />
        </div>
        {showDialog && (
          <CoverageGroupDialog
            organization={organization}
            coverageGroup={groupToEdit}
            onSubmit={groupToEdit ? onUpdateGroup : onCreateGroup}
            fetchUsedCoverageGroupKeys={fetchUsedCoverageGroupKeys}
            onClose={onClose}
          />
        )}
      </CardDialog>
    </LoadingSwitch>
  );
};

const AddCoverageGroupButton = ({ text, onClick }) => {
  const classes = useStyles();

  return (
    <FsButton color={FsButton.COLORS.primary} onClick={onClick}>
      <AddIcon className={classes.leftButtonIcon} iconColor={colors.buttonLink} />
      {text}
    </FsButton>
  );
};

const EmptyStateComponent = (props) => (
  <EmptyState
    subtitle="No coverage groups were configured yet"
    buttonComponent={<AddCoverageGroupButton {...props} />}
  />
);

const Title = () => (
  <Text weight="regular" variant="small">
    {COVERAGES_GROUPS_SUBTITLE}
  </Text>
);

AddCoverageGroupButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CoveragesGroups;

import React from 'react';

import CardDialog from '~/components/CardDialog';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import SortableTable from '~/components/core/Tables/SortableTable';
import useCurrencyFormatter from '~/components/CurrencyFormatterContext';
import useOrganization from '~/components/OrganizationContext';
import type { StatReserveConfiguration } from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveConfigurationPage';
import StatReserveConfigurationSubRulesTable from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveConfigurationSubRulesTable';
import StatReserveUpsertDialog from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveUpsertDialog';
import { isOrganizationAuOrg, isOrganizationUsOrg } from '~/Utils';

import PencilIcon from '../../../icons/PencilIcon';

export interface StatReserveConfigurationRowWithSortableId {
  id: number;
  statReserveConfiguration: StatReserveConfiguration;
}

export interface StatReserveConfigurationTableProps {
  statReserveConfigurationSortableRows?: StatReserveConfigurationRowWithSortableId[];
  reloadData: () => void;
  isLoading: boolean;
  isError: boolean;
}
const StatReserveConfigurationTable: React.FC<StatReserveConfigurationTableProps> = ({
  statReserveConfigurationSortableRows,
  reloadData,
  isError,
  isLoading,
}) => {
  const organization = useOrganization();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const subOrganizationEnabled = organization.subOrganizationEnabled;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { currencyFormatter } = useCurrencyFormatter();
  const [selectedRow, setSelectedRow] = React.useState<StatReserveConfiguration | null>(null);

  const handleSubmit = () => {
    reloadData();
    setSelectedRow(null);
  };
  const handleClose = () => {
    setSelectedRow(null);
  };

  const handleEditClick = (row: StatReserveConfigurationRowWithSortableId) => {
    setSelectedRow(row.statReserveConfiguration);
  };

  const columnData = [
    {
      id: StatReserveConfigurationTableColumns.coverage,
      label: StatReserveConfigurationTableColumns.coverage,
      numeric: false,
      specialCell: (row: StatReserveConfigurationRowWithSortableId) =>
        row.statReserveConfiguration.coverage_display_name,
    },
    {
      id: StatReserveConfigurationTableColumns.defaultIndemnityReserve,
      label: StatReserveConfigurationTableColumns.defaultIndemnityReserve,
      numeric: false,
      specialCell: (row: StatReserveConfigurationRowWithSortableId) =>
        currencyFormatter.format(row.statReserveConfiguration.indemnity_reserve_amount),
    },
    {
      id: StatReserveConfigurationTableColumns.defaultExpenseReserve,
      label: StatReserveConfigurationTableColumns.defaultExpenseReserve,
      numeric: false,
      specialCell: (row: StatReserveConfigurationRowWithSortableId) =>
        currencyFormatter.format(row.statReserveConfiguration.expenses_reserve_amount),
    },
    {
      id: StatReserveConfigurationTableColumns.edit,
      label: '',
      numeric: false,
      specialCell: (row: StatReserveConfigurationRowWithSortableId) => {
        return (
          <IconButton onClick={() => handleEditClick(row)} className="text-slate-900 hover:text-teal-700">
            <PencilIcon iconColor="currentColor" />
          </IconButton>
        );
      },
    },
    {
      id: StatReserveConfigurationTableColumns.subRules,
      label: '',
      numeric: false,
      expandable: true,
      isHidden: !subOrganizationEnabled && !(isOrganizationUsOrg(organization) || isOrganizationAuOrg(organization)),
      specialCell: (row: StatReserveConfigurationRowWithSortableId) => {
        const subRulesReservesList = row.statReserveConfiguration.sub_rules ?? [];
        return (
          <StatReserveConfigurationSubRulesTable
            subRulesReservesList={subRulesReservesList}
            statReserveConfiguration={row}
            reloadData={reloadData}
          />
        );
      },
    },
  ];

  return (
    <div>
      <CardDialog title="Coverages">
        {isLoading || isError || !statReserveConfigurationSortableRows ? (
          <SkeletonTable columnsCount={4} rowsCount={7} isError={isError} />
        ) : (
          <SortableTable
            columns={columnData}
            rows={statReserveConfigurationSortableRows.sort((a, b) =>
              a.statReserveConfiguration.coverage_display_name.localeCompare(
                b.statReserveConfiguration.coverage_display_name
              )
            )}
            disableSortByUser={true}
            shouldRenderExpandAsTable={true}
          />
        )}
      </CardDialog>
      {selectedRow ? (
        <StatReserveUpsertDialog statReserveConfig={selectedRow} onSubmit={handleSubmit} onClose={handleClose} />
      ) : null}
    </div>
  );
};

enum StatReserveConfigurationTableColumns {
  coverage = 'Coverage',
  defaultIndemnityReserve = 'Default Indemnity Reserve',
  defaultExpenseReserve = 'Default Expense Reserve',
  subRules = 'SubRules',
  edit = 'Edit',
}

export default StatReserveConfigurationTable;
